import {
  Button,
  Container,
  CustomDivider,
  Icon,
  Loader,
  TypoGraph,
} from "components";
import styles from "./wirePayment.module.scss";
import React, { useRef, useState } from "react";
import colors from "theme/colors";
import Dropzone from "react-dropzone";
import { IWhitelabelConfiguration } from "interfaces/IWhitelabelConfigurationResponse";
import { convertFileToBase64 } from "tools/base64";
import { useFormContext } from "react-hook-form";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";

interface IWirePaymentProps {
  base64?: string;
  whitelabelConfiguration?: IWhitelabelConfiguration;
  formattedAmount?: string;
}

const WirePayment: React.FC<IWirePaymentProps> = (props) => {
  const { base64, whitelabelConfiguration, formattedAmount } = props;
  const { setValue } = useFormContext();
  const pageRef = useRef<HTMLDivElement>(null);
  // PDF viewer configuration
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const prefixedBase64 = `data:application/pdf;base64,${base64}`;
  const [paymentConfirmationFile, setPaymentConfirmationFile] =
    useState<File>();

  const downloadPdf = () => {
    const attachmentBase64 = prefixedBase64;
    let alink = document.createElement("a");
    alink.href = attachmentBase64;
    alink.download = "Wiring instructions";
    alink.click();
  };

  const PaymentConfirmation = () => {
    if (paymentConfirmationFile) {
      return (
        <Container
          key={paymentConfirmationFile?.name}
          className={styles.filePreview}
        >
          <Container className={styles.fileNameContainer}>
            <Icon
              name="icon_uploadSuccess"
              size={35}
              color={whitelabelConfiguration?.backgroundColor}
            />
            <TypoGraph
              variant="caption"
              content={paymentConfirmationFile?.name}
              className={styles.text}
            />
          </Container>

          <Container className={styles.cancelIcon}>
            <Icon
              iconClassName={styles.fileCancelIcon}
              name="icon_filecancel"
              iconButton
              onClick={() => {
                setPaymentConfirmationFile(undefined);
              }}
            />
          </Container>
        </Container>
      );
    }

    async function validateAndSetFile(file: File) {
      setPaymentConfirmationFile(file);
      const paymentConfirmationBase64 = await convertFileToBase64(file);
      setValue(
        "wireConfirmationImageBase64",
        paymentConfirmationBase64?.toString()
      );
    }

    return (
      <Dropzone
        accept={{ "image/*": [] }}
        multiple={false}
        onDrop={async (file) => validateAndSetFile(file[0])}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={styles.dropzoneContainer}>
            <div
              {...getRootProps({
                className: "dropzone",
              })}
            >
              <input {...getInputProps()} />
              <Container className={styles.fileUploadText}>
                <Icon name="icon_fileupload" size={35} />
                <Container>
                  <TypoGraph content={"Upload Wiring Payment Confirmation"} />
                </Container>
              </Container>
            </div>
          </div>
        )}
      </Dropzone>
    );
  };

  if (base64) {
    return (
      <>
        <Container className={styles.amountContainer}>
          <TypoGraph component="span" content={`Amount`}></TypoGraph>
          <TypoGraph
            component="span"
            content={`$${formattedAmount}`}
            align={"right"}
          />
        </Container>
        <CustomDivider />
        <Container className={styles.totalContainer}>
          <TypoGraph component="span" content={`Total`}></TypoGraph>
          <TypoGraph
            component="span"
            content={`$${formattedAmount}`}
            align={"right"}
          />
        </Container>
        <CustomDivider title={"Wire transfer instructions"} />
        <Container
          className={styles.wireDetailsContainer}
          sx={{
            border: `1px solid ${
              whitelabelConfiguration?.backgroundColor
                ? whitelabelConfiguration.backgroundColor
                : colors.primary
            }`,
          }}
        >
          <Loader loading={base64 == ""} type="default" height="704px" />
          {base64 != "" && (
            <Document
              file={prefixedBase64}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                renderAnnotationLayer={false}
                renderTextLayer={false}
                className={styles.pageStyle}
                pageNumber={pageNumber}
              />
            </Document>
          )}
        </Container>
        <Container className={styles.downloadContainer}>
          <Button onClick={downloadPdf} label={"Download PDF"} />
        </Container>
        <CustomDivider title={"Upload Wire Payment confirmation"} />
        <PaymentConfirmation />
      </>
    );
  } else {
    return (
      <Container className={styles.securityContentContainer}>
        <Icon name="icon_info" size={25} />
        <TypoGraph
          color={colors.primary2}
          content={"No wiring instructions available for this payment request."}
        />
      </Container>
    );
  }
};

export default WirePayment;
