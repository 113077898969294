import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Button, Container, Icon, TypoGraph, Toast as toast } from "components";
import strings from "strings";
import styles from "./paymentRequestOtp.module.scss";
import { useState } from "react";
import colors from "theme/colors";
import OtpInput from "react18-input-otp";
import { sendOtpForRFP, validateOtpForRFP } from "api";

interface IPaymentRequestOtp {
  paymentRequestId: string;
  whitelabelPrimaryColor?: string;
  handleValidOptCode: () => void;
  handleClose: () => void;
}
const PaymentRequesOtpDialog = (props: IPaymentRequestOtp) => {
  const {
    paymentRequestId,
    whitelabelPrimaryColor,
    handleValidOptCode,
    handleClose,
  } = props;
  const t = strings.MULTIFACTOR_STRINGS;
  const [loading, setLoading] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [formValues, setFormValues] = useState({
    twoFactorAuth: "",
  });
  const { twoFactorAuth } = formValues;
  const [codeSent, setCodeSent] = useState(false);

  function validateCode() {
    setLoading(true);
    validateOtpForRFP(paymentRequestId, twoFactorAuth)
      .then((resp: any) => {
        toast({ title: resp.data.message, autoClose: 1500 });
        handleValidOptCode();
      })
      .catch(() => {
        setInvalidCode(true);
        setLoading(false);
      });
  }

  function sendCode() {
    setFormValues({ twoFactorAuth: "" });
    setInvalidCode(false);
    sendOtpForRFP(paymentRequestId)
      .then((resp: any) => {
        setCodeSent(true);
        toast({ title: resp.data.message, autoClose: 1500 });
      })
      .catch(() => {
        handleClose();
      });
  }

  return (
    <Dialog
      open={true}
      maxWidth="xl"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "40px",
          boxShadow: "0px 0px 150px 0px rgba(76, 43, 149, 0.5)",
        },
      }}
    >
      {codeSent && (
        <DialogContent>
          <Container className={styles.otpCodeContainer}>
            <Container className={styles.otpCodeContent}>
              <Icon
                type="icons"
                name="icon_shield_with_lock"
                color={
                  whitelabelPrimaryColor
                    ? whitelabelPrimaryColor
                    : colors.primary
                }
                size={64}
              ></Icon>
              <TypoGraph
                variant="body1"
                align="center"
                className={styles.otpWording}
              >
                Your one-time passcode has been sent. Please enter you code
                below.
              </TypoGraph>
              <OtpInput
                value={formValues.twoFactorAuth}
                onChange={(val: string) =>
                  setFormValues({ twoFactorAuth: val.toUpperCase() })
                }
                numInputs={6}
                containerStyle={styles.otpInputContainer}
                inputStyle={styles.otpInput}
              />
              {invalidCode && (
                <Container className={styles.invalidCodeContainer}>
                  <Icon
                    type="icons"
                    color={"#743C3B"}
                    name="icon_info"
                    size={25}
                  ></Icon>

                  <TypoGraph
                    className={styles.resendTxt}
                    color={"#743C3B"}
                    content={"Incorrect code, please try again."}
                  />
                </Container>
              )}

              <Container className={styles.buttonContainer}>
                <Button
                  className={styles.otpButton}
                  loading={loading}
                  disabled={twoFactorAuth.length != 6}
                  variant="contained"
                  label={"Submit code"}
                  onClick={() => {
                    validateCode();
                  }}
                />
              </Container>

              <Container className={styles.resendTxtContainer}>
                <TypoGraph
                  className={styles.resendTxt}
                  content={"Not working?"}
                />

                <TypoGraph
                  className={styles.resendTxt}
                  sx={{ fontWeight: "bolder" }}
                  content={"Request a new code, now."}
                  onClick={() => sendCode()}
                  color={
                    whitelabelPrimaryColor
                      ? whitelabelPrimaryColor
                      : colors.primary
                  }
                  link
                />
              </Container>
            </Container>
          </Container>
        </DialogContent>
      )}
      {!codeSent && (
        <DialogContent>
          <Container className={styles.otpCodeContainer}>
            <Container className={styles.otpCodeContent}>
              {whitelabelPrimaryColor && (
                <Icon
                  type="icons"
                  name="icon_shield_with_lock"
                  color={whitelabelPrimaryColor}
                  size={64}
                ></Icon>
              )}
              {!whitelabelPrimaryColor && (
                <Icon
                  type="icons"
                  name="icon_shield_with_lock"
                  color={colors.primary}
                  size={64}
                ></Icon>
              )}
              <TypoGraph align="center" className={styles.otpWording}>
                For security purposes, please click the button to recieve a
                one-time passcode
              </TypoGraph>

              <Container className={styles.sendCodebuttonContainer}>
                <Button
                  className={styles.otpButton}
                  loading={loading}
                  variant="contained"
                  label={"Send code"}
                  onClick={() => {
                    sendCode();
                  }}
                />
              </Container>
            </Container>
          </Container>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default PaymentRequesOtpDialog;
