import { Container, HotjarSuppressor, Icon, Toast as toast } from "components";
import TypoGraph from "components/typograph";
import React, { createRef } from "react";
import Dropzone from "react-dropzone";
import { useFormContext, Controller } from "react-hook-form";
import colors from "theme/colors";
import styles from "./dropzonefromfield.module.scss";
import { ErrorMessage } from "@hookform/error-message";
import Resizer from "react-image-file-resizer";

interface DropzoneControllerProps {
  name: string;
  label: string;
  defaultValue?: string;
  required?: boolean;
}

const DragAndDropFileFormField: React.FC<DropzoneControllerProps> = ({
  name,
  label,
  defaultValue = undefined,
  required = false,
}) => {
  const { control, setValue, getValues, clearErrors } = useFormContext();
  const dropzoneFrontRef: any = createRef();
  const isRequired = required ? "Field is Required" : undefined;
  const previewName = name + "preview";

  const resizeFile = async (file: File) =>
    new Promise<string>((resolve) => {
      Resizer.imageFileResizer(
        file,
        2560,
        1440,
        file.type === "image/png" ? "PNG" : "SVG",
        100,
        0,
        (uri) => resolve(uri.toString()),
        "base64"
      );
    });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: isRequired,
      }}
      render={({
        field: { onChange, value, onBlur },
        formState: { errors },
      }) => {
        const hasError = !!errors[name];

        return (
          <Container>
            <Dropzone
              ref={dropzoneFrontRef}
              accept={{ "image/png": [], "image/svg+xml": [] }}
              multiple={false}
              onDrop={async (file: any) => {
                try {
                  if (file[0].type === "image/png" || file[0].type === "image/svg+xml") {
                    const image = await resizeFile(file[0]);
                    setValue(name, image?.toString());
                    setValue(previewName, URL.createObjectURL(file[0]));
                    clearErrors(name); // Limpia el error al subir un archivo válido
                  } else {
                    toast({ type: "error", title: "Only PNG and SVG files are supported" });
                  }
                } catch {
                  setValue(name, undefined);
                  setValue(previewName, undefined);
                  toast({ type: "error", title: "Error uploading image" });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    className: `${styles.dropzoneContainer} ${hasError ? styles.errorBorder : ""}`,
                    onDrop: (event) => event.stopPropagation(),
                  })}
                >
                  <input {...getInputProps()} />
                  <Container className={styles.fileUploadText}>
                    {
                      !! value ? 
                      <img
                        height={46}
                        src={value}
                        alt="Uploaded Preview"
                        className={styles.previewImage}
                      />
                      :
                      <Icon 
                        name="icon_camera" 
                        size={46} 
                      />
                    }
                    <TypoGraph
                      content={label}
                      color={hasError ? colors.error : colors.primary}
                      className={`${styles.dropzoneText} ${hasError ? styles.errorText : ""}`}
                    />
                  </Container>
                </div>
              )}
            </Dropzone>
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <span className={styles.errorMessage}>{message}</span>
              )}
            />
          </Container>
        );
      }}
    />
  );
};

export default DragAndDropFileFormField;
