import * as React from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import { PrivDependantRoute } from "./PrivDependantRoute";
import { Actions, Resources } from "tools/privilegeChecker";
import WhiteLabelsPage from "pages/whiteLabels/whiteLabelsPage";
import WhiteLabelForm from "pages/whiteLabels/whiteLabelForm";

const WhiteLabelsRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.BILLING_ACCOUNT,
            }}
          >
            <WhiteLabelsPage />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:whiteLabelId"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.BILLING_ACCOUNT,
            }}
          >
            <WhiteLabelsPage />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/create"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.WRITE,
              resource: Resources.BILLING_ACCOUNT,
            }}
          >
            <WhiteLabelForm />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/:whiteLabelId/edit"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.UPDATE,
              resource: Resources.BILLING_ACCOUNT,
            }}
          >
            <WhiteLabelForm />
          </PrivDependantRoute>
        }
      />
    </Routes>
  );
};

export default WhiteLabelsRoutes;
