import { ToggleButton, ToggleButtonGroup, styled } from "@mui/material";
import { TypoGraph, Container } from "components";
import BaseDialog from "components/dialog/baseDialog";
import {
  InputFormField,
  DropdownFormField,
  RadioFormField,
  DatePickerFormField,
} from "components/hookForm";
import {
  DATE_FORMAT_YYYY_MM_DD,
  todayDate,
  tomorrowDate,
} from "constants/datePicker";
import {
  customPatternTypeOptions,
  endingOptions,
  weekdaysAbbreviated,
} from "constants/recurringPayment";
import { isDate } from "moment";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { formatDateToMMDDYYYY } from "tools/format";
import { formatDate } from "tools/validators";
import styles from "../paymentsList.module.scss";

interface IRequestPaymentCustomRecurrenceModalProps {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
}

const RequestPaymentCustomRecurrenceModal = ({
  open,
  handleSuccess,
  handleClose,
}: IRequestPaymentCustomRecurrenceModalProps) => {
  const { getValues, setValue, watch } = useFormContext();
  const [recurringEndDate, setRecurringEndDate] = useState<Date>(
    new Date(new Date().setDate(todayDate.getDate() + 1))
  );
  const [days, setDays] = useState([]);

  const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    borderRadius: "50% !important",
    width: "40px !important",
    height: "40px !important",
    border: "none !important",
    backgroundColor: "#F5F4FA",
    fontWeight: "normal",
    color: "#212121",
    "&.Mui-selected": {
      backgroundColor: "#7851f8",
      color: "white",
      "&:hover": {
        backgroundColor: "#6e689a",
      },
    },
  }));

  const CustomToggleButtonGroup = styled(ToggleButtonGroup)({
    marginTop: "24px !important",
    marginBottom: "24px !important",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  });

  useEffect(() => {
    let customType = getValues("customType");
    if (customType === "DAY") {
      setValue("weekdays", undefined);
    }
    if (customType === "MONTH") {
      setValue("weekdays", undefined);
    }
  }, [watch("customType")]);

  useEffect(() => {
    let endCondition = getValues("recurringEndCondition");

    if (endCondition === "NEVER") {
      setValue("recurringEndDate", undefined);
      setValue("maximumOccurrences", undefined);
      setValue("endDateSelect", "NOTEND");
    }
    if (endCondition === "DATE") {
      setValue("recurringEndDate", formatDateToMMDDYYYY(tomorrowDate));
      setValue("maximumOccurrences", undefined);
    }
    if (endCondition === "OCCURRENCE") {
      setValue("recurringEndDate", undefined);
      setValue("endDateSelect", undefined);
    }
  }, [watch("recurringEndCondition")]);

  useEffect(() => {
    setValue("weekdays", days);
  }, [days]);

  const fieldsPerType = (type: string) => {
    switch (type) {
      case "DAY":
        return <></>;
      case "WEEK":
        return (
          <>
            <TypoGraph
              sx={{ "margin-top": "16px !important" }}
              content="Repeat on"
            />
            <CustomToggleButtonGroup
              size="small"
              arial-label="Days of the week"
              value={days}
              onChange={(event: any, value: any) => {
                setDays(value);
              }}
            >
              {weekdaysAbbreviated.map((day) => (
                <CustomToggleButton
                  key={day.key.toString()}
                  value={day.key}
                  aria-label={day.key.toString()}
                >
                  {day.label}
                </CustomToggleButton>
              ))}
            </CustomToggleButtonGroup>
          </>
        );
      case "MONTH":
        return <></>;
      default:
        break;
    }
  };

  function formIsValid(): boolean {
    let type = getValues("customType");
    let endingCondition = getValues("recurringEndCondition");
    let customNumber = getValues("customNumber");
    if (
      !customNumber ||
      customNumber == "0" ||
      !type ||
      !getValues("recurringEndCondition")
    ) {
      return false;
    }
    if (type === "WEEK") {
      let weekdays = watch("weekdays");
      if (!weekdays || weekdays.length == 0) {
        return false;
      }
    }
    let endDate = watch("recurringEndDate");
    if (endingCondition === "DATE" && !endDate) {
      return false;
    }

    let maxOccurrences = watch("maximumOccurrences");
    if (
      endingCondition === "OCCURRENCE" &&
      (!maxOccurrences || maxOccurrences == "0")
    ) {
      return false;
    }
    return true;
  }

  return (
    <BaseDialog
      open={open}
      handleClose={() => {}}
      title="Custom Recurrence"
      content={
        <Container>
          <Container
            sx={{
              display: "grid",
              gridGap: "1rem",
              gridTemplateColumns: "auto auto auto",
              alignItems: "center",
            }}
          >
            <TypoGraph content="Repeat every" />
            <InputFormField
              required
              name={"customNumber"}
              label={""}
              type="number"
              min={1}
              maxLength={3}
              className={styles.repeatEveryField}
            />
            <DropdownFormField
              required
              name={"customType"}
              label={""}
              options={customPatternTypeOptions}
              className={styles.repeatEveryOptions}
            />
          </Container>
          <Container
            sx={{
              display: "grid",
              gridGap: "1rem",
              gridTemplateColumns: "auto auto auto",
              alignItems: "center",
            }}
          ></Container>
          {fieldsPerType(getValues("customType"))}
          <TypoGraph content="Ends " />
          <Container className={styles.endOptionsContainer}>
            <RadioFormField
              name={"recurringEndCondition"}
              radioGroupData={endingOptions}
              className={styles.endOptions}
            />
            <Container className={styles.endOptionFields}>
              <DatePickerFormField
                required={getValues("recurringEndCondition") === "DATE"}
                disabled={getValues("recurringEndCondition") !== "DATE"}
                name={"recurringEndDate"}
                onChange={(e: React.ChangeEvent<any> | null) => {
                  isDate(e) && setRecurringEndDate(e);
                  isDate(e) &&
                    setValue("recurringEndDate", formatDateToMMDDYYYY(e));
                }}
                minDate={tomorrowDate}
                defaultValue={formatDateToMMDDYYYY(tomorrowDate)}
                inputFormat={DATE_FORMAT_YYYY_MM_DD}
                value={
                  getValues("recurringEndDate") !== undefined
                    ? new Date(
                        formatDate(getValues("recurringEndDate")! as string)
                      )
                    : recurringEndDate
                }
                label={"End date"}
              />

              <InputFormField
                className={styles.ocurrencyField}
                required={getValues("recurringEndCondition") === "OCCURRENCE"}
                disabled={getValues("recurringEndCondition") !== "OCCURRENCE"}
                name={"maximumOccurrences"}
                label={"Max. occurrences"}
                min={1}
                maxLength={3}
              />
            </Container>
          </Container>
        </Container>
      }
      primaryBtnProps={{
        label: "Done",
        onClick: handleSuccess,
        disabled: !formIsValid(),
      }}
      secondaryBtnProps={{
        label: "Cancel",
        onClick: handleClose,
      }}
    />
  );
};

export { RequestPaymentCustomRecurrenceModal };
