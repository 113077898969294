import { Button, Container, Divider, Icon, TypoGraph } from "components";
import { ObjectStrings } from "interfaces";
import colors from "theme/colors";
import strings from "strings";
import { setupEmail2fa } from "api";
import { useState } from "react";

interface IResetProps {
  styles: ObjectStrings;
  handleStep: (val: number) => void;
}

const Setup2fa = (props: IResetProps) => {
  const { styles, handleStep } = props;
  const [loading, setLoading] = useState(false);
  const t = strings.MULTIFACTOR_STRINGS;

  return (
    <Container className={styles.multiFactorContainer}>
      <Container className={styles.multiFactorContent}>
        <TypoGraph
          variant="h6"
          content={t.MULTIFACTOR_AUTHENTICATION}
          align="center"
        />
        <TypoGraph variant="body2" content={t.USES_MFA} align="center" />
        <Divider
          sx={{
            width: "5rem",
            margin: "auto",
          }}
        />
        <TypoGraph
          variant="body2"
          content={t.CONTINUE_MFA_SIGNIN}
          align="center"
        />
        <Container className={styles.appStoreContainer}>
          <Icon
            name="playStore"
            type="images"
            boxClassName={styles.hoverImage}
            width={193}
            height={64}
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US",
                "_blank"
              )
            }
          />
          <Icon
            name="appStore"
            type="images"
            boxClassName={styles.hoverImage}
            width={193}
            height={64}
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/google-authenticator/id388497605",
                "_blank"
              )
            }
          />
        </Container>
        <Container>
          <Button
            variant="outlined"
            onClick={() => handleStep(3)}
            label={t.CONTINUE_GOOLE_AUTH}
            id={t.CONTINUE_GOOLE_AUTH}
            disabled={loading}
          />
          <Button
            className={styles.twoFaButton}
            variant="outlined"
            onClick={() => {
              setLoading(true);
              setupEmail2fa()
                .then(() => {
                  handleStep(7);
                })
                .finally(() => setLoading(false));
            }}
            loading={loading}
            loadingShowOutlined={true}
            label={t.CONTINUE_EMAIL_2FA_AUTH}
            id={t.CONTINUE_EMAIL_2FA_AUTH}
          />
        </Container>
      </Container>
    </Container>
  );
};
export default Setup2fa;
