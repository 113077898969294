import { FormEvent, useState } from "react";
import OtpInput from "react18-input-otp";
import {
  Button,
  Container,
  Dialog,
  Toast as toast,
  TypoGraph,
} from "components";
import { ObjectStrings } from "interfaces";
import strings from "strings";
import { login2fa, selfReset2faEmail } from "api";
import { useNavigate } from "react-router-dom";
import startIntercom from "tools/intercomConfig";
import colors from "theme/colors";
import { userIsCardholder } from "tools/privilegeChecker";
import startPendo from "tools/startPendo";

interface IResetProps {
  styles: ObjectStrings;
  handleStep: (val: number) => void;
}

const Login2fa = (props: IResetProps) => {
  const { styles, handleStep } = props;
  const [loading, setLoading] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [formValues, setFormValues] = useState({
    twoFactorAuth: "",
  });

  const { twoFactorAuth } = formValues;
  const navigate = useNavigate();

  const t = strings.MULTIFACTOR_STRINGS;

  const onReset2FAPress = () => {
    setDialogLoading(true);
    selfReset2faEmail()
      .then((response) => {
        toast({
          title: strings.SELF_RESET_2FA_EMAIL_HAS_BEEN_SENT,
          subTitle: strings.PLEASE_CHECK_YOUR_EMAIL,
        });
        setDialogVisible(false);
      })
      .finally(() => setDialogLoading(false));
  };

  const onLoginPressed = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    login2fa(parseInt(twoFactorAuth))
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        userIsCardholder();
        if (process.env.REACT_APP_INTERCOM_ENABLED === "true") {
          startIntercom(response?.data?.token);
        }
        startPendo(response?.data?.token);
        navigate("/payments");
      })
      .finally(() => setLoading(false));
  };
  return (
    <form onSubmit={onLoginPressed}>
      <Container className={styles.multiFactorContainer}>
        <Container className={styles.multiFactorContent}>
          <TypoGraph
            variant="h6"
            content={t.MULTIFACTOR_AUTHENTICATION}
            align="center"
          />
          <TypoGraph
            variant="body2"
            content={t.ENTER_CONFIRMATION_CODE}
            align="center"
          />
          <OtpInput
            value={formValues.twoFactorAuth}
            onChange={(val: string) => setFormValues({ twoFactorAuth: val })}
            numInputs={6}
            inputStyle={styles.otpInput}
            isInputNum
          />
          <Container className={styles.buttonContainer}>
            <Button
              type="submit"
              loading={loading}
              disabled={twoFactorAuth === ""}
              variant="contained"
              label={strings.LOGIN}
            />
          </Container>
          <Container>
            <Button
              variant="text"
              onClick={() => handleStep(0)}
              label={strings.CANCEL}
            />
            <Button
              variant="text"
              onClick={() => setDialogVisible(true)}
              label={strings.RESET_2FA}
            />
          </Container>
        </Container>
        <Dialog
          open={dialogVisible}
          handleClose={() => {
            setDialogVisible(false);
          }}
          title={strings.TWO_FACTOR_AUTH_RESET_CONFIRMATION}
          size="sm"
          content={
            <Container>
              <TypoGraph
                content={strings.TWO_FACTOR_AUTH_CONFIRMATION_TEXT}
                variant="body2"
                align={"justify"}
              />
            </Container>
          }
          primaryBtnProps={{
            label: strings.CONFIRM,
            loading: dialogLoading,
            disabled: dialogLoading,
            onClick: () => onReset2FAPress(),
          }}
        />
      </Container>
    </form>
  );
};

export default Login2fa;
