import {
  Blade,
  Button,
  Container,
  Icon,
  ImagePreviewDialog,
  Loader,
  SubmitDialog,
  TabPanel,
  Tabs,
  TypoGraph,
} from "components";
import strings from "strings";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../paymentsList.module.scss";
import {
  completedStatusById,
  dcFailStatusById,
  paymentsDetailTabs,
} from "constants/status";
import { PaymentDetailComponent, UpdateReceivableComponent } from "./index";
import PaymentNotesComponent from "./notes";
import PaymentHistoryComponent from "./history";
import {
  canChangeAmount,
  canChangeFileNumber,
  canDeposit,
  canHold,
  canOverrideItemStatus,
  canReject,
  canReturn,
  canSubmit,
  canSendReminder,
  DialogueType,
} from "constants/itemUpdateActions";
import { DropdownOptions, IPayment, IPaymentImageResponse } from "interfaces";
import {
  getCompanyAccountsForCompany,
  getPaymentById,
  getPaymentImage,
  getReceivableAccountsForCompany,
  getStatuses,
  resentReceivablePaymentRequest,
} from "api";
import toast from "components/toast";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReceivablePdfComponent from "./details/receivablePdf";
import { LightTooltip } from "components/toolTip/lightTooltip";
import { Box, List, ListItem } from "@mui/material";
import colors from "theme/colors";
import { paymentMethods } from "constants/paymentMethods";
import { useMutation } from "@tanstack/react-query";
import { paymentStatus } from "constants/paymentStatus";

interface IPaymentBladeProps {
  typeId: string | undefined;
  handleBladeSuccessCallBack: () => void;
  handleDataChange: () => void;
  paymentId: string | undefined;
}

const PaymentBladeComponent = ({
  typeId,
  handleBladeSuccessCallBack,
  paymentId,
  handleDataChange,
}: IPaymentBladeProps) => {
  const navigate = useNavigate();
  const [paymentDetailTab, setPaymentDetailTab] = useState<number>(0);
  const [availableStatuses, setAvailableStatuses] =
    useState<DropdownOptions[]>();
  const [availableAccounts, setAvailableAccounts] = useState<DropdownOptions[]>(
    []
  );
  const [availableReceivableAccounts, setAvailableReceivableAccounts] =
    useState<DropdownOptions[]>([]);
  const [selectedPaymentDetail, setSelectedPaymentDetail] =
    useState<IPayment>();
  const [bladeLoader, setBladeLoader] = useState<boolean>(false);
  const [imagePreviewModal, setImagePreviewModal] = useState<boolean>(false);
  const [showUpdateItemDialog, setShowUpdateItemDialog] =
    useState<boolean>(false);
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions[]>();
  const [dialogueType, setDialogueType] = useState<DialogueType>();
  const [paymentImage, setPaymentImage] = useState<
    IPaymentImageResponse | undefined
  >();
  const [currentImagePreview, setCurrentImagePreview] = useState<
    "front" | "back" | "wiringConfirmation"
  >();
  const [showSendReminderDialog, setShowSendReminderDialog] =
    useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const receivableStatusIsCompleted =
    selectedPaymentDetail &&
    completedStatusById.includes(selectedPaymentDetail?.statusId);

  const receivableStatusIsRecurring =
    selectedPaymentDetail && selectedPaymentDetail?.statusId == 27;

  const recurringPaymentIsActive =
    selectedPaymentDetail?.recurringPaymentIsActive;

  const overrideStatuesAllowed = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 25, 26];

  const receivableIsNotPassportHeld = !selectedPaymentDetail?.heldByPassport;

  const passportProcessableItem =
    selectedPaymentDetail?.passportProcessableItem;

  const deluxeProcessableItem = selectedPaymentDetail?.deluxeProcessableItem;

  const receivableCanBeMarkedAsCompleted =
    selectedPaymentDetail &&
    selectedPaymentDetail.paymentMethodId == 4 &&
    selectedPaymentDetail?.statusId == 0;

  useEffect(() => {
    (async () => {
      if (!(paymentId && typeId)) {
        return;
      }
      setBladeLoader(true);
      try {
        const payment = (await getPaymentById(parseInt(paymentId))).data;
        setSelectedPaymentDetail(payment);
        const isDcNotFailed =
          (payment?.statusId || payment?.statusId === 0) &&
          !dcFailStatusById.includes(payment?.statusId);
        if (!isDcNotFailed) {
          return;
        }
        const paymentImageResponse = await getPaymentImage(
          payment?.id ? payment.id : 0
        );
        setPaymentImage(paymentImageResponse.data);

        if (
          (payment.paymentMethodId === paymentMethods.ELECTRONIC_PAYMENT ||
            payment.statusId == paymentStatus.PASSPORT_RC_COMPLETED) &&
          payment.passportProcessableItem
        ) {
          setAvailableAccounts(
            (await getReceivableAccountsForCompany(payment.companyId))?.data
              ?.filter(
                (account) =>
                  account.paymentTypes.find(
                    (paymentType: any) => paymentType.name === payment.type
                  ) &&
                  account.paymentMethods.find(
                    (paymentMethod) =>
                      paymentMethod.name === payment.paymentMethod
                  ) !== undefined
              )
              .map((account) => {
                return {
                  value: account.id,
                  label: account.accountName,
                  externalDepositPaymentMethods:
                    account.externalDepositPaymentMethods,
                  accountType: "receivable",
                };
              })
          );
        } else if (payment.deluxeProcessableItem) {
          setAvailableAccounts(
            (await getCompanyAccountsForCompany(payment.companyId))?.data
              ?.filter(
                (account) =>
                  account.paymentType === payment.type &&
                  account.paymentMethods.find(
                    (paymentMethod) =>
                      paymentMethod.name === payment.paymentMethod &&
                      account.active
                  ) !== undefined
              )
              .map((account) => {
                return {
                  value: account.id,
                  label: account.name,
                  accountType: "deluxe",
                };
              })
          );
        }

        if (
          payment.passportProcessableItem &&
          (payment.statusId == paymentStatus.NEW ||
            payment.statusId == paymentStatus.READ)
        ) {
          setAvailableReceivableAccounts(
            (await getReceivableAccountsForCompany(payment.companyId))?.data
              ?.filter(
                (account) =>
                  account.paymentTypes.find(
                    (paymentType: any) => paymentType.name === payment.type
                  ) &&
                  account.paymentMethods.find(
                    (paymentMethod) =>
                      paymentMethod.name === payment.paymentMethod
                  ) !== undefined
              )
              .map((account) => {
                return {
                  value: account.id,
                  label: account.accountName,
                  externalDepositPaymentMethods:
                    account.externalDepositPaymentMethods,
                  accountType: "receivable",
                };
              })
          );
        }

        setAvailableStatuses(
          (await getStatuses()).data
            .filter((status) => overrideStatuesAllowed.includes(status.id))
            .map((status) => {
              return {
                value: status.id.toString(),
                label: status.name,
              };
            })
        );
      } catch {
        navigate(`/payments/${typeId}`);
      } finally {
        setBladeLoader(false);
      }
    })();
    handleDataChange();
  }, [paymentId, typeId]);

  const updatePaymentData = async () => {
    setBladeLoader(true);
    const payment = (await getPaymentById(parseInt(paymentId!))).data;
    setSelectedPaymentDetail(payment);
    const paymentImageResponse = await getPaymentImage(
      payment?.id ? payment.id : 0
    );
    setPaymentImage(paymentImageResponse.data);
    setBladeLoader(false);
  };

  const BladeContent = () => {
    if (bladeLoader) {
      return <Loader loading={bladeLoader} />;
    }
    return (
      <Container className={styles.bladeBodyContainer}>
        <Container className={styles.bladeTabContainer}>
          <Tabs
            tabData={paymentsDetailTabs}
            value={paymentDetailTab}
            handleChange={(_e, val) => setPaymentDetailTab(val)}
          />
          <TabPanel value={paymentDetailTab} index={0}>
            <PaymentDetailComponent
              payment={selectedPaymentDetail}
              paymentImage={paymentImage}
              handleImageNode={(index) => {
                setImagePreviewModal(true);
                setCurrentImagePreview(index);
              }}
            />
          </TabPanel>
          <TabPanel value={paymentDetailTab} index={1}>
            <PaymentNotesComponent
              payment={selectedPaymentDetail}
              handleSuccessCallback={handleBladeSuccessCallBack}
            />
          </TabPanel>
          <TabPanel value={paymentDetailTab} index={2}>
            <PaymentHistoryComponent
              payment={selectedPaymentDetail}
              handleSuccessCallback={handleBladeSuccessCallBack}
            />
          </TabPanel>
        </Container>
      </Container>
    );
  };

  function onActionClicked(
    type: DialogueType,
    availableOptions?: DropdownOptions[]
  ): void {
    setShowUpdateItemDialog(true);
    setDialogueType(type);
    setDropdownOptions(availableOptions);
  }

  const onActionSendReminder = useMutation(
    (id: string | undefined) => {
      return resentReceivablePaymentRequest(id);
    },
    {
      onError: (error) => {
        setShowUpdateItemDialog(false);
        navigate(`/payments/${typeId}`);
      },
      onSuccess: (response) => {
        setShowUpdateItemDialog(false);
        toast({
          title: response.message,
        });
        navigate(`/payments/${typeId}`);
      },
    }
  );

  const BladeFooter = () => {
    return (
      <Container className={styles.bladeFooterContainer}>
        {selectedPaymentDetail?.canDeleteSubmitAttempt && (
          <Button
            id={strings.CLEAR_SUBMIT_ATTEMPT}
            label={strings.CLEAR_SUBMIT_ATTEMPT}
            variant="outlined"
            onClick={() => onActionClicked("clearSubmitAttempt")}
          />
        )}
        {selectedPaymentDetail && !receivableStatusIsRecurring && (
          <PDFDownloadLink
            style={{ color: colors.white }}
            document={
              <ReceivablePdfComponent
                payment={selectedPaymentDetail}
                paymentImage={paymentImage}
              />
            }
            fileName={`receivable_${selectedPaymentDetail.id}`}
          >
            {({ loading }) => (
              <Button
                id={"Download"}
                label={"Download"}
                color={"primary"}
                variant={"outlined"}
                disabled={loading}
              />
            )}
          </PDFDownloadLink>
        )}
        {canOverrideItemStatus(selectedPaymentDetail?.paymentMethodId) &&
          !receivableStatusIsRecurring && (
            <Button
              id={"Override"}
              label="Override"
              variant="outlined"
              onClick={() => onActionClicked("override", availableStatuses)}
            />
          )}
        {canReject(selectedPaymentDetail?.statusId) && (
          <Button
            id={"Reject"}
            label="Reject"
            color="error"
            onClick={() => onActionClicked("reject")}
          />
        )}
        {canHold(
          selectedPaymentDetail?.statusId,
          selectedPaymentDetail?.paymentMethodId
        ) && (
          <Button
            id={"Hold"}
            label="Hold"
            color="warning"
            onClick={() => onActionClicked("hold")}
          />
        )}
        {canDeposit(
          selectedPaymentDetail?.statusId,
          selectedPaymentDetail?.paymentMethodId,
          passportProcessableItem || deluxeProcessableItem
        ) &&
          receivableIsNotPassportHeld && (
            <Button
              id={"Deposit"}
              label="Deposit"
              color="success"
              onClick={() =>
                onActionClicked(
                  "approve",
                  availableAccounts.concat(availableReceivableAccounts)
                )
              }
            />
          )}

        {canReturn(
          selectedPaymentDetail?.statusId,
          selectedPaymentDetail?.paymentMethodId
        ) ? (
          <Button
            id={"Return"}
            label="Return"
            variant="outlined"
            onClick={() => onActionClicked("return")}
          />
        ) : null}
        {canSubmit(selectedPaymentDetail?.statusId) ? (
          <Button
            id={"Submit"}
            label="Submit"
            color="success"
            onClick={() => onActionClicked("submit")}
          />
        ) : null}
        {canSendReminder(selectedPaymentDetail?.statusId) && (
          <Button
            id={"Reminder"}
            label="Send Reminder"
            color="primary"
            loading={buttonLoader}
            onClick={() => setShowSendReminderDialog(true)}
          />
        )}
        {receivableStatusIsRecurring && (
          <Button
            id={"DeleteRecurring"}
            label="Delete"
            color="error"
            onClick={() =>
              onActionClicked("deleteRecurring", availableStatuses)
            }
          />
        )}
        {receivableCanBeMarkedAsCompleted && (
          <Button
            id={"Pause"}
            label="Mark as Completed"
            color="success"
            onClick={() => onActionClicked("markAsCompleted")}
          />
        )}
        {!receivableStatusIsCompleted &&
          !receivableStatusIsRecurring &&
          receivableIsNotPassportHeld && (
            <LightTooltip
              arrow={true}
              title={
                <List dense={true}>
                  <TypoGraph content="Additional Actions" />
                  {canChangeFileNumber(selectedPaymentDetail?.statusId) && (
                    <ListItem disableGutters>
                      <Button
                        id={"Change File number"}
                        label="Change File number"
                        variant="text"
                        onClick={() => onActionClicked("changeFileNumber")}
                      />
                    </ListItem>
                  )}
                  {canChangeAmount(selectedPaymentDetail?.statusId) && (
                    <ListItem disableGutters>
                      <Button
                        id={"Change Amount"}
                        label="Change Amount"
                        variant="text"
                        onClick={() => onActionClicked("changeAmount")}
                      />
                    </ListItem>
                  )}
                </List>
              }
            >
              <Box
                sx={{
                  alignSelf: "center",
                  height: 20,
                }}
              >
                <Icon name="icon_moreVertical"></Icon>
              </Box>
            </LightTooltip>
          )}
        {receivableStatusIsRecurring && recurringPaymentIsActive && (
          <Button
            id={"Pause"}
            label="Pause"
            color="warning"
            onClick={() => onActionClicked("pause")}
          />
        )}
        {receivableStatusIsRecurring && !recurringPaymentIsActive && (
          <Button
            id={"Resume"}
            label="Resume"
            color="success"
            onClick={() => onActionClicked("unpause")}
          />
        )}
      </Container>
    );
  };

  return (
    <>
      <Blade
        show={true}
        title={!bladeLoader ? strings.RECEIVABLE_DETAILS : ""}
        handleClose={() => navigate(`/payments/${typeId}`)}
        content={<BladeContent />}
        footerContent={!bladeLoader && <BladeFooter />}
      />
      {showUpdateItemDialog && selectedPaymentDetail && (
        <UpdateReceivableComponent
          selectedPaymentDetail={selectedPaymentDetail}
          handleSuccess={(itemId: number, title: string) => {
            toast({
              title: title,
              subTitle: `${strings.ITEM_ID}: ${itemId}`,
            });
          }}
          typeId={typeId}
          dropdownOptions={dropdownOptions}
          dialogueType={dialogueType}
          setShowUpdateItemDialog={setShowUpdateItemDialog}
          showUpdateItemDialog={showUpdateItemDialog}
          handleUpdateData={() => {
            updatePaymentData();
          }}
        />
      )}
      <SubmitDialog
        type="warning"
        title={strings.CONFIRM_SEND_REMINDER}
        open={showSendReminderDialog}
        body1={`${strings.ITEM_WILL_BE_SENT_A_REMINDER} ${selectedPaymentDetail?.laname}`}
        handleClose={() => setShowSendReminderDialog(false)}
        primaryBtnProps={{
          id: "SendReminder",
          label: "Send Reminder",
          onClick: () => {
            onActionSendReminder.mutate(paymentId);
          },
          loading: onActionSendReminder.isLoading,
          color: "success",
        }}
      />
      <ImagePreviewDialog
        open={imagePreviewModal}
        handleClose={() => setImagePreviewModal(false)}
        payment={selectedPaymentDetail}
        paymentImage={paymentImage}
        initialImagePreview={currentImagePreview}
        wireConfirmationImageBase64={
          selectedPaymentDetail?.wireConfirmationImageBase64
        }
        frontImage={paymentImage?.images.find((i) => i.imageIndex === "FRONT")}
        backImage={paymentImage?.images.find((i) => i.imageIndex === "BACK")}
      />
    </>
  );
};

export { PaymentBladeComponent };
