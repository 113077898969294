const availableEarnestMoneyRoles = [
  { value: "0", label: "Buyer" },
  { value: "2", label: "Listing Agent" },
  { value: "3", label: "Selling Agent" },
  { value: "4", label: "Settlement Agent" },
];

const availableEarnestMoneyPlusOptionRoles = [
  { value: "0", label: "Buyer" },
  { value: "2", label: "Buyer's Agent" },
  { value: "4", label: "Settlement Agent" },
];

const allEarnestMoneyRoles = [
  { value: "0", label: "Buyer" },
  { value: "1", label: "Seller" },
  { value: "2", label: "Listing Agent" },
  { value: "3", label: "Selling Agent" },
  { value: "4", label: "Settlement Agent" },
];

const commissionEarnedRoles = [
  { value: "2", label: "Listing Agent" },
  { value: "3", label: "Selling Agent" },
  { value: "4", label: "Settlement Agent" },
];

const securityDepositRoles = [
  { value: "0", label: "TENANT" },
  { value: "1", label: "LANDLORD/OWNER" },
  { value: "2", label: "LISTING (OWNER'S) AGENT" },
  { value: "3", label: "LEASING(TENANT'S) AGENT" },
  { value: "4", label: "SETTLEMENT AGENT" },
];

const earnestBroker = [
  { value: "0", label: "LISTING BROKER " },
  { value: "1", label: "SELLING BROKER " },
  { value: "2", label: "ATTORNEY " },
  { value: "3", label: "TITLE CO." },
];

const boolOptions = [
  { value: "0", label: "NO" },
  { value: "1", label: "YES" },
];

const twoFactorAuthAvailableMethods = [
  { value: 1, label: "Email" },
  { value: 2, label: "App" },
];

const passportAccountTypes = [
  { value: "SAVINGS", label: "Savings" },
  { value: "CHECKING", label: "Checking" },
];

const passportAccountHolderTypes = [
  { value: "CONSUMER", label: "Consumer" },
  { value: "CORPORATE", label: "Corporate" },
];

export {
  availableEarnestMoneyRoles,
  availableEarnestMoneyPlusOptionRoles,
  allEarnestMoneyRoles,
  commissionEarnedRoles,
  securityDepositRoles,
  earnestBroker,
  boolOptions,
  twoFactorAuthAvailableMethods,
  passportAccountTypes,
  passportAccountHolderTypes,
};
