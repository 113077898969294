import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import {
  createWhitelabelConfiguration,
  getCompaniesAdminByUserWithoutWhiteLabelonfig,
  getWhitelabelConfigurationById,
  updateWhitelabelConfiguration,
  validateSubDomain,
} from "api";
import { DropdownOptions } from "interfaces";
import {
  Button,
  Card,
  Container,
  Divider,
  HeaderBar,
  Loader,
  Toast as toast,
  TypoGraph,
} from "components";
import { InputFormField, MultiSelectFormField } from "components/hookForm";
import styles from "./whiteLabelForm.module.scss";
import strings from "strings";
import {
  IWhitelabelConfigResponse,
  IWhitelabelConfigurationRequest,
  NewWhitelabelConfigurationResponse,
} from "interfaces/IWhitelabelConfigurationRequest";
import DragAndDropFileFormField from "components/hookForm/dragAndDropFileFormField";
import Typograph from "components/typograph";
import InputColorFormField from "components/hookForm/inputColorFormField";
import { Typography } from "@mui/material";

const WhiteLabelForm: React.FunctionComponent = () => {
  const methods = useForm<IWhitelabelConfigurationRequest>({});

  const { handleSubmit, reset, getValues, watch, control } = methods;

  const { whiteLabelId } = useParams();

  const [whiteLabelPreviousData, setWhiteLabelPreviousData] =
    useState<IWhitelabelConfigResponse>();

  const isEdit = !!whiteLabelId;
  const navigate = useNavigate();

  const [companiesOptions, setCompaniesOptions] = useState<DropdownOptions[]>();
  const [
    companiesOptionsWithoutWhiteLabel,
    setCompaniesOptionsWithoutWhiteLabel,
  ] = useState<DropdownOptions[]>();

  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  const [subDomainError, setSubDomainError] = useState<string>();

  const validateSubDomainField = async (): Promise<boolean> => {
    const subDomain = getValues("subDomain");
    let isValid = true;

    if (subDomain && !isEdit) {
      try {
        const resp = await validateSubDomain(subDomain);
        isValid = resp?.data?.valid || false;
      } catch (error) {
        console.error("Error validating subdomain:", error);
        isValid = false;
      }
    }

    setSubDomainError(isValid ? "" : "Invalid Sub-domain.");
    return isValid;
  };

  const loadWhiteLabelDetails = useCallback((id: string) => {
    setLoading(true);

    getWhitelabelConfigurationById(id)
      .then((response) => {
        setWhiteLabelPreviousData(response.data);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!!whiteLabelId) {
      loadWhiteLabelDetails(whiteLabelId);
    }

    loadCompaniesWithoutWhiteLabelonfig();
  }, [whiteLabelId]);

  useEffect(() => {
    if (!!whiteLabelId) {
      const formValues = {
        id: parseInt(whiteLabelId, 10),
        subDomain: whiteLabelPreviousData?.subDomain,
        companiesIds: whiteLabelPreviousData?.companiesIds,
        currentCompaniesIds: whiteLabelPreviousData?.companiesIds,
        newCompaniesIds: [],
        favIconBase64: whiteLabelPreviousData?.favIconBase64,
        logoBase64: whiteLabelPreviousData?.logoBase64,
        configJson: {
          backgroundColor: whiteLabelPreviousData?.configJson.backgroundColor,
          subtitle: whiteLabelPreviousData?.configJson.subtitle,
          pageName: whiteLabelPreviousData?.configJson.pageName,
        },
      };
      if (whiteLabelPreviousData?.companies) {
        const companyOptions = whiteLabelPreviousData.companies.map(
          (company) => {
            return {
              value: company.id,
              label: company.name,
            };
          }
        );

        setCompaniesOptions(companyOptions);
      }

      reset(formValues);
    }
  }, [whiteLabelPreviousData]);

  const navigateToWhitelabels = () => {
    navigate("/settings/whitelabels");
  };

  const onSubmit = (
    whitelabelForm: IWhitelabelConfigurationRequest,
    type: string
  ) => {
    let action;
    setBtnLoader(true);

    switch (type) {
      case strings.CREATE:
        action = createWhitelabelConfiguration(whitelabelForm).then(
          (resp: AxiosResponse<NewWhitelabelConfigurationResponse>) => {
            toast({
              title: "Created Successfully",
              subTitle: `id: ${resp.data.subDomain}`,
            });
            navigateToWhitelabels();
          }
        );
        break;
      case strings.EDIT:
        if (whiteLabelId) {
          action = updateWhitelabelConfiguration(whitelabelForm).then(() => {
            toast({
              title: "Updated Successfully",
              subTitle: `id: ${whiteLabelId}, Sub domain: ${whitelabelForm.subDomain}`,
            });
            navigateToWhitelabels();
          });
        }
        break;
      default:
        break;
    }
  };

  const loadCompaniesWithoutWhiteLabelonfig = useCallback(() => {
    setLoading(true);
    getCompaniesAdminByUserWithoutWhiteLabelonfig()
      .then((response) => {
        const companyOptions = response?.data?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setCompaniesOptionsWithoutWhiteLabel(companyOptions);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container>
      <HeaderBar
        title={`${isEdit ? strings.EDIT : strings.CREATE} ${
          strings.WHITE_LABELS_STRING.WHITE_LABELS_ACCOUNTS
        }`}
        primaryBtnProps={{
          id: isEdit
            ? strings.WHITE_LABELS_STRING.UPDATE_WHITE_LABEL
            : strings.WHITE_LABELS_STRING.CREATE_WHITE_LABEL,
          label: isEdit
            ? strings.WHITE_LABELS_STRING.UPDATE_WHITE_LABEL
            : strings.WHITE_LABELS_STRING.CREATE_WHITE_LABEL,
          onClick: handleSubmit((data) =>
            onSubmit(data, isEdit ? strings.EDIT : strings.CREATE)
          ),
          loading: btnLoader,
        }}
      />
      <Loader loading={loading} />
      <Card>
        <TypoGraph
          variant="h2"
          sx={{ paddingTop: 1, paddingBottom: 2 }}
          content={`${
            isEdit
              ? `${strings.EDIT} ${strings.WHITE_LABELS_STRING.WHITE_LABEL}`
              : `${strings.CREATE} ${strings.WHITE_LABELS_STRING.WHITE_LABEL}`
          }`}
        />
        <Container>
          <FormProvider {...methods}>
            <DragAndDropFileFormField
              name="favIconBase64"
              label="Fav Icon"
              required
            />
            <br />
            <DragAndDropFileFormField name="logoBase64" label="Logo" required />
            <MultiSelectFormField
              name="currentCompaniesIds"
              label={"Current companies"}
              options={companiesOptions}
              disabled
            />
            <MultiSelectFormField
              name="companiesIds"
              label={"New companies"}
              options={companiesOptionsWithoutWhiteLabel}
              required={!isEdit}
            />
            <InputFormField
              name="configJson.subtitle"
              label={"Slogan"}
              required
              minLength={{ value: 2, message: "Minimum length is  2" }}
              maxLength={100}
            />
            <Container>
              <InputFormField
                disabled={isEdit}
                name="subDomain"
                label={"Sub-Domain"}
                required
                validate={validateSubDomainField}
                onBlur={validateSubDomainField}
                minLength={{ value: 4, message: "Minimum length is  4" }}
                maxLength={50}
              />
              {subDomainError && (
                <Typograph
                  color="#F9635E"
                  sx={{ "font-size": "0.75rem", "margin-right": "14px" }}
                  content={subDomainError}
                ></Typograph>
              )}
              <Typography
                variant="body2"
                sx={{ marginBottom: 2, fontStyle: "italic" }}
              >
                Enter your subdomain (this can only be set one time).
              </Typography>
            </Container>

            <InputColorFormField
              name="configJson.backgroundColor"
              label={"Background Color"}
              required
              minLength={{ value: 4, message: "Minimum length is  2" }}
              maxLength={4}
            />
            <Typography
              variant="body2"
              sx={{ marginBottom: 2, fontStyle: "italic" }}
            >
              To apply a color: Click the color bar, select the paint eyedropper
              icon, and hover over your desired color to apply it.
            </Typography>
            <InputFormField
              name="configJson.pageName"
              label={"Page Name"}
              required
              minLength={{ value: 2, message: "Minimum length is  2" }}
              maxLength={100}
            />
          </FormProvider>
        </Container>
        <Divider />
        <Container className={styles.actionContainer}>
          {isEdit ? (
            <Button
              id={strings.WHITE_LABELS_STRING.UPDATE_WHITE_LABEL + " Bottom"}
              loading={btnLoader}
              label={strings.WHITE_LABELS_STRING.UPDATE_WHITE_LABEL}
              onClick={handleSubmit((data) => onSubmit(data, strings.EDIT))}
            />
          ) : (
            <Button
              id={strings.WHITE_LABELS_STRING.CREATE_WHITE_LABEL + " Bottom"}
              loading={btnLoader}
              label={strings.WHITE_LABELS_STRING.CREATE_WHITE_LABEL}
              onClick={handleSubmit((data) => {
                onSubmit(data, strings.CREATE);
              })}
            />
          )}
          <Button
            id={strings.CANCEL}
            variant="outlined"
            label={strings.CANCEL}
            onClick={() => navigateToWhitelabels()}
          />
        </Container>
      </Card>
    </Container>
  );
};

export default WhiteLabelForm;
