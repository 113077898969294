import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Container, HotjarSuppressor, Icon, Loader } from "components";
import { canvasConfig } from "constants/component.config";
import styles from "./cropEditor.module.scss";
import { IPayment, IPaymentImage } from "interfaces";
import { cropImage, cropPreview, getPaymentImage, rotateImage } from "api";
import { CropRequest } from "interfaces/ICrop";
import Typograph from "components/typograph";

interface IProps {
  payment: IPayment | undefined;
  backImage: IPaymentImage | undefined;
  frontImage: IPaymentImage | undefined;
  imagesCropped: boolean;
  imagesGetsCropped?: (params: any) => void;
}

let vertices = [...canvasConfig.vertices];
let closeEnough = canvasConfig.closeEnough;
let mouseX, mouseY;
let selectedVertexIndex: number | null = null;
let isDragging = false;
let offsetX: number, offsetY: number;

const FRONT = "FRONT";
const BACK = "BACK";

const CropEditor: React.FunctionComponent<IProps> = (props) => {
  const { payment, backImage, frontImage, imagesCropped, imagesGetsCropped } =
    props;
  const [base64Image, setBase64Image] = useState(
    `data:image/png;base64, ${frontImage?.base64Image}`
  );

  const [backBase64Image, setBackBase64Image] = useState(
    `data:image/png;base64, ${backImage?.base64Image}`
  );
  const [frontBase64Image, setFrontBase64Image] = useState(
    `data:image/png;base64, ${frontImage?.base64Image}`
  );
  const [imageId, setImageId] = useState(props.frontImage?.id || 0);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null | undefined>(
    null
  );
  const [showPreview, setPreview] = useState(false);
  const [croppedImage, setCroppedImage] = useState("");
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [backCropConfirmed, setBackCropConfirmed] = useState(false);
  const [frontCropConfirmed, setFrontCropConfirmed] = useState(false);
  const [imageSide, setImageSide] = useState(FRONT);
  const [loading, setLoading] = useState(false);
  const [initCrop, setInitCrop] = useState(true);
  const [sideChanges, setSideChanges] = useState(true);
  const [scaledVerticesRequest, setScaledVerticesRequest]: any =
    useState(undefined);
  const [loadingRotate, setLoadingRotate] = useState(false);
  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const zoomCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [showZoom, setShowZoom] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setImageDimensions({ width: image.width, height: image.height });
    };
    image.src = base64Image;
  }, [base64Image]);

  useEffect(() => {
    if (!showPreview) {
      const image = new Image();
      image.onload = () => {
        setImageDimensions({ width: image.width, height: image.height });
        imgRef.current = image;
      };
      image.src = base64Image;
    }
  }, [base64Image, showPreview]);

  useEffect(() => {
    if (showPreview) {
      const image = new Image();
      image.onload = () => {
        setImageDimensions({ width: image.width, height: image.height });
      };
      image.src = croppedImage;
    }
  }, [croppedImage, showPreview]);

  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!showPreview && (initCrop || sideChanges)) {
      setCtx(null);
      const initialVertices = calculateInitialVertices(
        canvasSize.width,
        canvasSize.height
      );
      canvasConfig.vertices = initialVertices;
      vertices = [...canvasConfig.vertices];
      closeEnough = canvasConfig.closeEnough;
      mouseX = 0;
      mouseY = 0;
      isDragging = false;
      offsetX = 0;
      offsetY = 0;
      selectedVertexIndex = null;
      reOffset();
      if (!sideChanges) {
        setInitCrop(false);
      }
      setSideChanges(false);
    }
  }, [canvasSize]);

  useEffect(() => {
    if (imageDimensions) {
      const maxWidth = 700;
      const maxHeight = 500;
      const widthRatio = maxWidth / imageDimensions.width;
      const heightRatio = maxHeight / imageDimensions.height;
      const ratio = Math.min(widthRatio, heightRatio);

      setCanvasSize({
        width: imageDimensions.width * ratio,
        height: imageDimensions.height * ratio,
      });

      if (!showPreview) {
        setCtx(null);
      }
    }
  }, [imageDimensions]);

  const width = canvasSize.width ?? canvasConfig.width;
  const height = canvasSize.height ?? canvasConfig.height;

  useEffect(() => {
    if (imagesCropped) {
      setLoading(true);
      getPaymentImage(payment!.id)
        .then((image) => {
          if (image) {
            const back = image.data.images
              ?.filter((i) => i.imageIndex === BACK)
              .pop();
            if (back) {
              setBackBase64Image(`data:image/png;base64, ${back?.base64Image}`);
            }

            const front = image.data.images
              ?.filter((i) => i.imageIndex === FRONT)
              .pop();
            if (front) {
              setInitCrop(true);

              setBase64Image(`data:image/png;base64, ${front?.base64Image}`);

              setFrontBase64Image(
                `data:image/png;base64, ${front?.base64Image}`
              );
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [payment]);

  function calculateInitialVertices(canvasWidth: number, canvasHeight: number) {
    const maxSquareSize = Math.min(canvasWidth, canvasHeight) * 0.5;

    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;

    return [
      { x: centerX - maxSquareSize / 2, y: centerY - maxSquareSize / 2 }, // Top left
      { x: centerX + maxSquareSize / 2, y: centerY - maxSquareSize / 2 }, // Top right
      { x: centerX + maxSquareSize / 2, y: centerY + maxSquareSize / 2 }, // Bottom right
      { x: centerX - maxSquareSize / 2, y: centerY + maxSquareSize / 2 }, // Bottom left
    ];
  }

  const reOffset = () => {
    let BB = canvasRef?.current?.getBoundingClientRect();
    offsetX = BB?.left || 0;
    offsetY = BB?.top || 0;
  };

  const isMouseInVertex = (mx: number, my: number, vx: number, vy: number) => {
    return Math.abs(mx - vx) < closeEnough && Math.abs(my - vy) < closeEnough;
  };

  const drawCircle = useCallback(
    (x: number, y: number, radius: number, active: boolean) => {
      ctx?.beginPath();
      ctx?.setLineDash([0]);
      ctx?.arc(x, y, radius, 0, 2 * Math.PI);
      ctx?.closePath();
      ctx?.stroke();
    },
    [ctx]
  );

  const drawPolygon = useCallback(() => {
    if (!ctx) return;
    ctx.beginPath();
    ctx.moveTo(vertices[0].x, vertices[0].y);
    for (let i = 1; i < vertices.length; i++) {
      ctx.lineTo(vertices[i].x, vertices[i].y);
    }

    if (ctx?.lineWidth) {
      ctx.lineWidth = 2;
    }
    ctx?.setLineDash([8]);
    ctx.closePath();
    ctx.stroke();

    for (let i = 0; i < vertices.length; i++) {
      drawCircle(
        vertices[i].x,
        vertices[i].y,
        closeEnough,
        i === selectedVertexIndex
      );
    }
  }, [ctx, drawCircle]);

  const mouseMove = useCallback(
    (e: any) => {
      mouseX = e.clientX - offsetX;
      mouseY = e.clientY - offsetY;

      let zoomCtx = zoomCanvasRef.current?.getContext("2d");
      if (zoomCtx && imgRef.current) {
        zoomCtx.clearRect(0, 0, width, height);
        let zoomSize = 30;

        let sx = mouseX - zoomSize;
        let sy = mouseY - zoomSize;
        let sWidth = zoomSize * 2;
        let sHeight = zoomSize * 2;

        let imgWidth = imgRef.current.width;
        let imgHeight = imgRef.current.height;

        // For draw the zoom area
        zoomCtx.fillStyle = "white";
        zoomCtx.shadowColor = "rgba(0, 0, 0, 1)";
        zoomCtx.shadowBlur = 2;
        zoomCtx.shadowOffsetX = 1;
        zoomCtx.shadowOffsetY = 1;
        zoomCtx.fillRect(0, 0, 100, 100);

        zoomCtx.drawImage(
          imgRef.current,
          sx / (width / imgWidth),
          sy / (height / imgHeight),
          sWidth / (width / imgWidth),
          sHeight / (height / imgHeight),
          0,
          0,
          100,
          100
        );

        zoomCtx.beginPath();
        zoomCtx.moveTo(50, 40);
        zoomCtx.lineTo(50, 60);
        zoomCtx.moveTo(40, 50);
        zoomCtx.lineTo(60, 50);
        zoomCtx.strokeStyle = "white";
        zoomCtx.lineWidth = 2;
        zoomCtx.stroke();
      }

      if (isDragging && selectedVertexIndex !== null) {
        vertices[selectedVertexIndex].x = mouseX;
        vertices[selectedVertexIndex].y = mouseY;
      }

      ctx?.clearRect(0, 0, width, height);
      drawPolygon();
    },
    [ctx, drawPolygon]
  );

  const mouseDown = useCallback(
    (e: any) => {
      reOffset();
      mouseX = e.clientX - offsetX;
      mouseY = e.clientY - offsetY;

      selectedVertexIndex = null;
      for (let i = 0; i < vertices.length; i++) {
        if (isMouseInVertex(mouseX, mouseY, vertices[i].x, vertices[i].y)) {
          selectedVertexIndex = i;
          break;
        }
      }

      if (selectedVertexIndex !== null) {
        isDragging = true;
        setShowZoom(true);
      }

      ctx?.clearRect(0, 0, width, height);
      drawPolygon();
    },
    [ctx, drawPolygon]
  );

  const mouseUp = useCallback(() => {
    isDragging = false;
    selectedVertexIndex = null;
    setShowZoom(false);
  }, []);

  const initCanvas = useCallback(() => {
    let currentCanvas = canvasRef.current;
    let ctx = currentCanvas?.getContext("2d");
    ctx?.clearRect(0, 0, width, height);
    setCtx(ctx);
    ctx?.stroke();

    if (ctx?.strokeStyle) {
      ctx.strokeStyle = "white";
      ctx.shadowColor = "rgba(0, 0, 0, 1)";
      ctx.shadowBlur = 2;
      ctx.shadowOffsetX = 1;
      ctx.shadowOffsetY = 1;
    }

    ctx?.clearRect(0, 0, width, height);

    reOffset();
    window.onscroll = reOffset;
    window.onresize = reOffset;
    if (currentCanvas) {
      currentCanvas.onresize = reOffset;
    }
    currentCanvas?.addEventListener("mousedown", mouseDown);
    currentCanvas?.addEventListener("mouseup", mouseUp);
    currentCanvas?.addEventListener("mousemove", mouseMove);
    drawPolygon();
  }, [mouseDown, mouseMove, mouseUp, drawPolygon]);

  const getCropForCurrentItem = (confirm?: boolean) => {
    const originalWidth = imageDimensions?.width || 1;
    const originalHeight = imageDimensions?.height || 1;
    const scaleX = width / originalWidth;
    const scaleY = height / originalHeight;

    // Scale the vertices according to the current canvas size
    const scaledVertices = canvasConfig.vertices.map((vertex) => ({
      x: vertex.x / scaleX,
      y: vertex.y / scaleY,
    }));

    if (!confirm) {
      setScaledVerticesRequest(scaledVertices);
    }

    if (confirm) {
      const request: CropRequest = {
        checkImageId: imageId,
        topLeft: scaledVerticesRequest[0],
        topRight: scaledVerticesRequest[1],
        bottomRight: scaledVerticesRequest[2],
        bottomLeft: scaledVerticesRequest[3],
      };

      setLoadingConfirm(true);

      cropImage(request).then((response) => {
        if (response.data.endorsedImage) {
          setCroppedImage(
            `data:image/png;base64,${response.data.endorsedImage}`
          );
          if (imageSide == BACK) {
            setBackCropConfirmed(true);
            setBackBase64Image(
              `data:image/png;base64,${response.data.endorsedImage}`
            );
          } else {
            setFrontCropConfirmed(true);
            setFrontBase64Image(
              `data:image/png;base64,${response.data.endorsedImage}`
            );
          }
          imagesGetsCropped?.(true);
          setPreview(true);
          setLoadingConfirm(false);
        }
      });
    } else {
      const request: CropRequest = {
        checkImageId: imageId,
        topLeft: scaledVertices[0],
        topRight: scaledVertices[1],
        bottomRight: scaledVertices[2],
        bottomLeft: scaledVertices[3],
      };

      setLoadingPreview(true);

      cropPreview(request).then((response) => {
        if (response.data.endorsedImage) {
          setCroppedImage(
            `data:image/png;base64,${response.data.endorsedImage}`
          );

          setPreview(true);
          setLoadingPreview(false);
        }
      });
    }
  };

  const rotateCurrentImage = () => {
    if (loadingRotate) {
      return;
    }
    setLoadingRotate(true);
    rotateImage(imageId).then((response) => {
      if (response.data.endorsedImage) {
        setBase64Image(`data:image/png;base64,${response.data.endorsedImage}`);
        if (imageSide == BACK) {
          setBackBase64Image(
            `data:image/png;base64,${response.data.endorsedImage}`
          );
        } else {
          setFrontBase64Image(
            `data:image/png;base64,${response.data.endorsedImage}`
          );
        }
      }
      setLoadingRotate(false);
      imagesGetsCropped?.(true);
    });
  };

  useEffect(() => {
    if (showPreview) {
      const currentCanvas = canvasRef.current;
      const cropPreview = new Image();
      cropPreview.src = croppedImage;
      const currentImg = imgRef.current;
      const ctx = currentCanvas?.getContext("2d");
      ctx?.clearRect(0, 0, width, height);
      if (currentImg) {
        currentImg.onload = () => {
          ctx?.drawImage(cropPreview, 0, 0, 700, 500);
        };
      }
    }
  }, [showPreview]);

  useEffect(() => {
    if (!showPreview) {
      initCanvas();
    }
  }, [initCanvas, showPreview]);

  useEffect(() => {
    (async () => {
      if (!showPreview) {
        return;
      }
      // Race condition, if we try to run this without the delay nothing will be drawn
      await new Promise((resolve) => setTimeout(resolve, 0));
      const currentCanvas = canvasRef.current;
      if (!currentCanvas) {
        return;
      }
      const request: any = vertices.map((vertex) => ({
        x: vertex.x,
        y: vertex.y,
      }));
    })();
  }, [showPreview]);

  const showComponent = () => {
    return (
      (imageSide == BACK && !backCropConfirmed) ||
      (imageSide == FRONT && !frontCropConfirmed)
    );
  };

  const setImageForEdit = (imageSideSelected: string) => {
    if (imageSideSelected == FRONT) {
      if (frontCropConfirmed) {
        setCroppedImage(frontBase64Image);
      } else {
        setBase64Image(frontBase64Image);
        setImageId(props.frontImage?.id || 0);
        setPreview(frontCropConfirmed);
      }
      setImageSide(imageSideSelected);
      setSideChanges(true);
    } else {
      if (backCropConfirmed) {
        setCroppedImage(backBase64Image);
      } else {
        setBase64Image(backBase64Image);
        setImageId(props.backImage?.id || 0);
        setPreview(backCropConfirmed);
      }

      setImageSide(imageSideSelected);
      setSideChanges(true);
    }
  };

  const previewBtnLabel = () => {
    if (loadingRotate) {
      return "Rotating image";
    }
    if (loadingPreview) {
      return "Loading preview";
    } else {
      return "Preview";
    }
  };

  useEffect(() => {
    if (backCropConfirmed) {
      setPreview(backCropConfirmed);
    }
    if (frontCropConfirmed) {
      setPreview(frontCropConfirmed);
    }
  }, [croppedImage]);

  const FrontBackBtns = () => {
    return (
      <Container className={styles.frontBackBtnsContainer}>
        <Button
          id={"Front"}
          label={"Front of Check"}
          variant={imageSide == FRONT ? undefined : "outlined"}
          disabled={loadingPreview || loadingRotate}
          onClick={() => {
            if (imageSide != FRONT) {
              setPreview(!showPreview);

              setImageForEdit(FRONT);
            }
          }}
        />
        <Button
          id={"Back"}
          label={"Back of Check"}
          variant={imageSide == BACK ? undefined : "outlined"}
          disabled={loadingPreview || loadingRotate}
          onClick={() => {
            if (imageSide != BACK) {
              setPreview(!showPreview);

              setImageForEdit(BACK);
            }
          }}
        />
      </Container>
    );
  };

  return (
    <Container>
      <HotjarSuppressor>
        {loading ? (
          <Loader loading={loading} type={"default"} height="35vh"></Loader>
        ) : (
          <Container className={styles.canvasContainer}>
            {/*if image is not cropped, and we want to display the preview*/}
            {showPreview && (
              <Container>
                <FrontBackBtns />
                <Container className={styles.drawerContainer}>
                  <Icon
                    imageId="source"
                    url={croppedImage || base64Image}
                    type="images"
                    boxClassName={styles.canvasImgContainer}
                  />
                  <canvas
                    ref={canvasRef}
                    width={width}
                    height={height}
                    style={{
                      zIndex: 2,
                      marginTop: 10,
                    }}
                  />
                </Container>
                {showComponent() && (
                  <Container>
                    <Container className={styles.cropBtnsContainer}>
                      <Button
                        id={"Reset Changes"}
                        label={"Reset Changes"}
                        variant={"outlined"}
                        onClick={() => {
                          setPreview(!showPreview);
                        }}
                      />
                      <Button
                        id={"Confirm"}
                        label={"Confirm"}
                        loading={loadingConfirm}
                        onClick={() => {
                          getCropForCurrentItem(true);
                        }}
                      />
                    </Container>
                    <Container className={styles.cropConfirmTextContainer}>
                      <Typograph
                        align={"center"}
                        variant={"h3"}
                        content={
                          "Confirming changes will irreversibly modify the image. Once cropped, the original image cannot be restored."
                        }
                      ></Typograph>
                    </Container>
                  </Container>
                )}
              </Container>
            )}
            {/*if the image is not cropped, we display the crop option*/}
            {!showPreview && (
              <Container>
                <FrontBackBtns />
                <Container className={styles.drawerContainer}>
                  <Icon
                    imageId="source"
                    url={base64Image}
                    type="images"
                    boxClassName={styles.canvasImgContainerEdit}
                    width={width}
                    height={height}
                  />
                  <canvas
                    ref={canvasRef}
                    width={width}
                    height={height}
                    style={{ zIndex: 2, marginTop: 16 }}
                  />
                  <canvas
                    ref={zoomCanvasRef}
                    width={100}
                    height={100}
                    style={{
                      zIndex: 3,
                      position: "absolute",
                      borderRadius: 50,
                      border: "2px solid white",
                      boxShadow: "0px 0px 6px 0px rgba(0,0,0,1)",
                      display: showZoom ? "block" : "none",
                    }}
                  />
                </Container>
                <Icon
                  name="icon_rotate"
                  color="black"
                  size={40}
                  boxClassName={styles.rotateIcon}
                  onClick={() => {
                    rotateCurrentImage();
                  }}
                  iconButton
                />
                {showComponent() && (
                  <Container className={styles.cropBtnContainer}>
                    <Button
                      id={"Preview"}
                      label={previewBtnLabel()}
                      loading={loadingPreview || loadingRotate}
                      onClick={() => {
                        getCropForCurrentItem();
                      }}
                    />
                  </Container>
                )}
              </Container>
            )}
          </Container>
        )}
      </HotjarSuppressor>
    </Container>
  );
};

export default CropEditor;
