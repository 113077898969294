import { useEffect, useState } from "react";
import { Backdrop, Box } from "@mui/material";
import { Button, Container, Icon, Loader, TypoGraph } from "components";
import { logout } from "api";
import { useNavigate, useParams } from "react-router";
import Login from "./partial/login";
import ForgotPassword from "./partial/forgotPassword";
import ResetPassword from "./partial/resetPassword";
import styles from "./loginContainer.module.scss";
import strings from "strings";
import colors from "theme/colors";
import Setup2fa from "./partial/setup2fa";
import Login2fa from "./partial/login2fa";
import Confirm2faRest from "./partial/confirm2faReset";
import Info2fa from "./partial/Info2fa";
import LoginEmail2fa from "./partial/loginEmail2fa";

interface IAppLogin {
  currentStep: number;
  resetPassword?: boolean;
  createPassword?: boolean;
}

const AppLogin: React.FC<IAppLogin> = (props) => {
  const { currentStep, resetPassword, createPassword } = props;
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  /**
   * step state used to show/hide the login forms
   * step number(0) -> to show the login form
   * step number(1) -> to show the forgotPassword form
   * step number(2) -> to show the resetForm form
   * step number(3) -> to show the setup2fa screen
   * step number(4) -> to show login2fa form
   */
  const [step, setStep] = useState(currentStep);
  const [is2fa, setIs2fa] = useState(false);
  const { resetId } = useParams();

  const FormComponent = (props: { step: number }) => {
    const { step } = props;
    switch (step) {
      case 0:
        return <Login styles={styles} handleStep={setStep} />;
      case 1:
        return <ForgotPassword styles={styles} handleStep={setStep} />;
      case 2:
        return <ResetPassword styles={styles} handleStep={setStep} />;
      case 3:
        return <Setup2fa styles={styles} handleStep={setStep} />;
      case 4:
        return <Login2fa styles={styles} handleStep={setStep} />;
      case 5:
        return <Confirm2faRest styles={styles} handleStep={setStep} />;
      case 6:
        return <Info2fa styles={styles} handleStep={setStep} />;
      case 7:
        return <LoginEmail2fa styles={styles} handleStep={setStep} />;
      default:
        return <Login styles={styles} handleStep={setStep} />;
    }
  };

  useEffect(() => {
    switch (step) {
      case 3:
      case 4:
      case 6:
        setIs2fa(true);
        break;
      case 7:
        setIs2fa(true);
        break;
      default:
        setIs2fa(false);
        break;
    }
  }, [step]);

  if (resetPassword && token) {
    logout().then(() => {
      localStorage.removeItem("token");
      if (createPassword) {
        navigate(`/create-password/${resetId}`);
      } else {
        navigate(`/reset-password/${resetId}`);
      }
    });
    return (
      <Backdrop open={true} sx={{ background: "white", zIndex: 2000 }}>
        <Loader
          loading={true}
          type="default"
          containerClassName={styles.resetLoader}
        />
      </Backdrop>
    );
  }

  return (
    <Container>
      <Box component="main">
        <Container className={styles.loginWrapperContainer}>
          <Container className={styles.LoginContent}>
            <Container
              className={`${styles.loginContainer} ${
                is2fa && styles.autoWidth
              }`}
            >
              <Container className={styles.brandDetail}>
                <Icon name="icon_logoFullname" height={100} width={360} />
                <Container className={styles.sloganContainer}>
                  <TypoGraph
                    className={styles.sloganText}
                    content={`the good funds payment portal`}
                  />
                </Container>
              </Container>
              <FormComponent step={step} />
              {!is2fa && (
                <Container>
                  <Container className={styles.demoTxtContainer}>
                    <TypoGraph
                      variant="subtitle1"
                      content={strings.NOT_REGISTERED}
                    />

                    <TypoGraph
                      variant="body1"
                      content={strings.WANT_TO_SEE_DEMO}
                      onClick={() =>
                        window.open("https://getbankshot.com/#demo", "_blank")
                      }
                      color={colors.primary}
                      link
                    />
                  </Container>
                </Container>
              )}
            </Container>
            <Container className={styles.sendPaymentContainer}>
              <Icon name="icon_b_logo_primary_bg" height={72} width={72} />

              <Button
                className={styles.sendPaymentButton}
                id={strings.LOGIN_WITH_GOOGLE}
                endIcon="icon_right_arrow"
                type="button"
                variant="outlined"
                label={"Send a Bank Shot payment"}
                onClick={() => {
                  window.open(
                    window.location.origin + "/receivables",
                    "_blank"
                  );
                }}
              />
            </Container>
          </Container>

          <Container className={styles.bsInfo}>
            <TypoGraph
              variant="overline"
              content="© 2025, Bank Shot"
              align="center"
              color={colors.grey}
              sx={{
                fontSize: "16px !important",
              }}
            />
          </Container>
        </Container>
      </Box>
    </Container>
  );
};

export default AppLogin;
