import * as React from "react";
import MaterialButton from "@mui/material/Button";
import MuiIconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Icon from "components/icon";
import colors from "theme/colors";

export interface IButtonProps {
  id?: string;
  variant?: "text" | "contained" | "outlined";
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  onClick?: (params?: any) => void;
  startIcon?: string;
  endIcon?: string;
  label?: string;
  fullWidth?: boolean;
  className?: string;
  loading?: boolean;
  pill?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | "neutral"
    | undefined;
  loadingShowOutlined?: boolean;
  customColor?: string;
  customBackgroundColor?: string;
}

const Button: React.FunctionComponent<IButtonProps> = (props) => {
  const {
    id,
    type,
    variant = "contained",
    disabled = false,
    size = "medium",
    startIcon,
    endIcon,
    onClick = () => {},
    label,
    fullWidth = true,
    className,
    loading = false,
    pill = true,
    color,
    loadingShowOutlined = false,
    customColor,
    customBackgroundColor,
  } = props;

  const MuiButton = styled(MaterialButton)(({ theme }) => ({
    borderRadius: pill ? 25 : theme.shape.borderRadius,
    textTransform: "none",
    fontFamily: "Inter",
    fontWeight: 600,
    color: customColor,
    borderColor: customBackgroundColor,
    backgroundColor: customBackgroundColor,
    transition: "background-color 0.7s ease",
    "&:hover": {
      backgroundColor: customBackgroundColor,
      mixBlendMode: "darken",
      boxShadow: "box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5)",
      transition: "box-shadow 0.7s ease",
    },
  }));

  if (!label) {
    return <></>;
  }

  return (
    <MuiButton
      id={id}
      type={type}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      onClick={() => !loading && onClick()}
      fullWidth={fullWidth}
      className={className}
      startIcon={startIcon && !loading && <Icon name={startIcon} />}
      endIcon={endIcon && <Icon name={endIcon} />}
    >
      {loading &&
        (loadingShowOutlined ? (
          <CircularProgress
            size={20}
            sx={{
              color: colors.primary,
              marginRight: "15px",
            }}
          />
        ) : (
          <CircularProgress
            size={20}
            sx={{
              color: colors.white,
              marginRight: "15px",
            }}
          />
        ))}
      {label}
    </MuiButton>
  );
};

export default Button;

interface IIconButtonProps {
  icon: string;
  size?: "small" | "medium" | "large";
}

const IconButton: React.FunctionComponent<IIconButtonProps> = (props) => {
  const { icon, size = "large" } = props;
  return (
    <MuiIconButton size={size}>
      <Icon name={icon} />
    </MuiIconButton>
  );
};

export { IconButton };
