import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Blade,
  Button,
  Container,
  DataGrid,
  HeaderBar,
  Loader,
  SubmitDialog,
  TableComponent,
  Tabs,
  Toast as toast,
} from "components";
import {
  deleteCompany,
  getAllCompanies,
  getCompanyDetail,
  getWhitelabelConfiguration,
  reactivateCompany,
} from "api";
import { ICompanyResponse, IPageResponse } from "interfaces";
import {
  allCompaniesTableColumns,
  companyFilters,
  companyDetailsLabel,
} from "constants/tableData";
import strings from "strings";
import styles from "./companies.module.scss";
import {
  Actions,
  defineUserPriv,
  PrivilegeLevels,
  Resources,
  userCan,
} from "tools/privilegeChecker";
import { AxiosResponse } from "axios";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";

const Companies: React.FunctionComponent = () => {
  const [companiesPage, setCompaniesPage] =
    useState<IPageResponse<ICompanyResponse>>();
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<number | string>(0);
  const [company, setCompany] = useState<ICompanyResponse>();
  const [showBlade, setShowBlade] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [reactivateModal, setReactivateModal] = useState<boolean>(false);
  const [confirmBtnLoader, setConfirmBtnLoader] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [bladeLoader, setBladeLoader] = useState<boolean>(false);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const userPriv = defineUserPriv();
  const [companyFilterTab, setCompanyFilterTab] = useState(0);
  const [subDomain, setSubDomain] = useState<string | null>(null);
  const handleSortChange = (model: GridSortModel) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };
  const navigate = useNavigate();
  const { companyId } = useParams();

  useEffect(() => {
    if (companyId) {
      setShowBlade(true);
      setBladeLoader(true);
      setSelectedCompany(companyId);
      getCompanyDetail(companyId)
        .then((res) => {
          setCompany(res?.data);
        })
        .catch(() => {
          navigate(`/settings/companies`);
        })
        .finally(() => {
          setBladeLoader(false);
        });

      getWhitelabelConfiguration(companyId).then((resp) => {
        setSubDomain(resp?.data?.subDomain);
      });
    } else {
      setShowBlade(false);
    }
  }, [companyId]);

  const loadCompanies = useCallback(
    async (
      pageNumber: number = 0,
      pageSize: number = 10,
      statusFilter: string = "",
      filter: string = ""
    ) => {
      setLoading(true);
      try {
        const companies = await getAllCompanies(
          pageNumber,
          pageSize,
          statusFilter,
          filter,
          sortModel[0]?.field,
          sortModel[0]?.sort
        );
        const companiesList = { ...companies.data };
        setCompaniesPage(companiesList);
      } finally {
        setLoading(false);
      }
    },
    [sortModel]
  );

  useEffect(() => {
    loadCompanies(
      0,
      currentPageSize,
      companyFilters[companyFilterTab].filterValue,
      searchValue
    );
  }, [companyFilterTab, loadCompanies, currentPageSize, searchValue]);

  const BladeContent = () => {
    if (bladeLoader) {
      return <Loader loading={bladeLoader} />;
    }
    return (
      <Container className={styles.dataGridContainer}>
        <DataGrid columns={companyDetailsLabel} data={company} />
      </Container>
    );
  };

  const closeBlade = () => {
    setShowBlade(false);
    navigate("/settings/companies");
  };

  const BladeFooter = () => {
    return (
      <Container className={styles.actionContainer}>
        <Button
          size={"small"}
          id={strings.COPY_URL}
          variant="outlined"
          label={strings.COPY_URL}
          onClick={() => handleCopyToClipboard()}
        />
        {userCan(Actions.GENERATE_MAINTENANCE_REPORT, Resources.COMPANY) ? (
          <Button
            size={"small"}
            id={strings.MAINTENANCE_REPORT}
            variant="outlined"
            label={strings.MAINTENANCE_REPORT}
            onClick={() => {
              navigate(`maintenance`);
            }}
          />
        ) : null}
        {userCan(Actions.READ, Resources.ACCOUNT) ? (
          <Button
            size={"small"}
            id={strings.DELUXE_ACCOUNTS}
            variant="outlined"
            label={strings.DELUXE_ACCOUNTS}
            onClick={() => {
              navigate(`accounts`);
            }}
          />
        ) : null}
        {userCan(Actions.WRITE, Resources.COMPANY) ? (
          <>
            <Button
              size={"small"}
              id={strings.UPDATE}
              variant="outlined"
              label={strings.UPDATE}
              onClick={() => {
                navigate(`edit`);
              }}
            />
            {company?.deleted === false && (
              <Button
                size={"small"}
                id={strings.DELETE}
                color="error"
                label={strings.DELETE}
                onClick={() => setDeleteModal(true)}
              />
            )}
            {company?.deleted === true &&
              userCan(Actions.REACTIVATE, Resources.COMPANY) && (
                <Button
                  size={"small"}
                  id={strings.REACTIVATE}
                  variant="outlined"
                  label={strings.REACTIVATE}
                  onClick={() => setReactivateModal(true)}
                />
              )}
          </>
        ) : null}
      </Container>
    );
  };

  const deleteCompanyAccount = () => {
    setConfirmBtnLoader(true);
    deleteCompany(+selectedCompany)
      .then((resp: AxiosResponse<ICompanyResponse>) => {
        toast({
          title: "Company deleted Successfully",
          subTitle: `Name: ${resp?.data?.name}\nId: ${resp?.data?.id}`,
        });
        setShowBlade(false);
        loadCompanies();
        setDeleteModal(false);
        navigate("/settings/companies");
      })
      .finally(() => {
        setConfirmBtnLoader(false);
      });
  };

  const reactivateCompanyAccount = () => {
    setConfirmBtnLoader(true);
    reactivateCompany(+selectedCompany)
      .then((resp: AxiosResponse<ICompanyResponse>) => {
        toast({
          title: "Company reactivated Successfully",
          subTitle: `Name: ${resp?.data?.name}\nId: ${resp?.data?.id}`,
        });
        setShowBlade(false);
        loadCompanies();
        setReactivateModal(false);
        navigate("/settings/companies");
      })
      .finally(() => {
        setConfirmBtnLoader(false);
      });
  };

  const handleQuickSearch = (value: string) => {
    setSearchValue(value);
    loadCompanies(0, 10, companyFilters[companyFilterTab].filterValue, value);
  };

  const handleCopyToClipboard = () => {
    const companyCodeText =
      company && company.brokerCode ? company.brokerCode : " ";
    const baseURL = window.location.origin;
    const textArea = document.createElement("textarea");
    if (!!subDomain) {
      textArea.value =
        "https://" +
        subDomain +
        ".getbankshot.com" +
        "/receivables/" +
        companyCodeText;
    } else {
      textArea.value = baseURL + "/receivables/" + companyCodeText;
    }
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      document.body.removeChild(textArea);
      toast({
        title: "Company URL copied successfully",
      });
    } catch (error) {
      toast({
        title: "Error on copy Company URL",
        type: "error",
      });
    }
  };

  return (
    <Container>
      <HeaderBar
        title={strings.ALL_COMPANIES}
        primaryBtnProps={
          userCan(Actions.WRITE, Resources.COMPANY)
            ? {
                id: "Create a new company",
                label: "Create a new company",
                onClick: () => navigate("/settings/companies/create"),
              }
            : undefined
        }
      />
      {userPriv === PrivilegeLevels.OWNER ? (
        <Tabs
          tabData={companyFilters || []}
          value={companyFilterTab}
          handleChange={(_e, val) => setCompanyFilterTab(val)}
        />
      ) : null}

      <TableComponent
        title={strings.ALL_COMPANIES}
        data={companiesPage?.items || []}
        totalCount={companiesPage?.totalCount}
        columns={allCompaniesTableColumns}
        loading={loading}
        searchValue={searchValue}
        handleQuickSearch={handleQuickSearch}
        handlePagination={(pageNumber, pageSize) => {
          setCurrentPageSize(pageSize);
          loadCompanies(
            pageNumber,
            pageSize,
            companyFilters[companyFilterTab].filterValue,
            searchValue
          );
        }}
        handleSortModelChange={handleSortChange}
        onRowClick={(row) => {
          navigate(`/settings/companies/${row.id}`);
        }}
      />
      <Blade
        show={showBlade}
        title={!bladeLoader ? strings.COMPANY_DETAILS : ""}
        handleClose={() => navigate("/settings/companies")}
        content={<BladeContent />}
        footerContent={!bladeLoader && <BladeFooter />}
      />
      <SubmitDialog
        type="delete"
        title={strings.CONFIRM_DELETE}
        open={deleteModal}
        body1={`Are you sure you want to delete the company with ID ${selectedCompany} ?`}
        body2={strings.CONFIRM_DELETE_CAPTION}
        handleClose={() => setDeleteModal(false)}
        primaryBtnProps={{
          id: strings.DELETE,
          label: strings.DELETE,
          loading: confirmBtnLoader,
          onClick: deleteCompanyAccount,
        }}
      />
      <SubmitDialog
        type="warning"
        title={strings.REACTIVATE}
        open={reactivateModal}
        body1={`Are you sure you want to reactivate the company with ID ${selectedCompany} ?`}
        handleClose={() => setReactivateModal(false)}
        primaryBtnProps={{
          id: strings.REACTIVATE,
          label: strings.REACTIVATE,
          loading: confirmBtnLoader,
          onClick: reactivateCompanyAccount,
        }}
      />
    </Container>
  );
};

export default Companies;
