import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getBillingAccountInvoices } from "api";
import { IBillingAccountInvoice } from "interfaces";
import {
  Blade,
  Button,
  Container,
  DataGrid,
  Divider,
  HeaderBar,
  Loader,
  TableComponent,
  TypoGraph,
} from "components";
import {
  billingAccountInvoicesColumns,
  billingAccountInvoicesDetails,
} from "constants/tableData";
import strings from "strings";
import styles from "./billingAccountInvoices.module.scss";
import { useParams } from "react-router-dom";
import formatAmount from "tools/format";

const BillingAccountInvoices: React.FunctionComponent = () => {
  const [billingAccountInvoices, setBillingAccountInvoices] = useState<
    IBillingAccountInvoice[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [showBlade, setShowBlade] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [bladeLoader, setBladeLoader] = useState<boolean>(false);
  const [selectedInvoice, setselectedInvoice] =
    useState<IBillingAccountInvoice>();

  const navigate = useNavigate();
  const { billingAccountId, invoiceId } = useParams();

  const t = strings.MY_BILLING_STRING;

  useEffect(() => {
    if (invoiceId) {
      setShowBlade(true);
      setBladeLoader(true);
      const selectedInvoice = billingAccountInvoices?.find(
        (invoice) => invoice.id === invoiceId
      );
      if (selectedInvoice) {
        setselectedInvoice(selectedInvoice);
      }
      setBladeLoader(false);
    } else {
      setShowBlade(false);
    }
  }, [invoiceId, billingAccountInvoices]);

  const loadBillingAccounts = useCallback(
    (pageNumber: number = 0, pageSize: number = 10) => {
      if (!billingAccountId) {
        return;
      }
      setLoading(true);
      getBillingAccountInvoices(+billingAccountId, pageNumber, pageSize)
        .then((response) => {
          setBillingAccountInvoices(response.data);
        })
        .finally(() => setLoading(false));
    },
    [billingAccountId]
  );
  billingAccountInvoicesColumns[0].handleClick = (
    value: IBillingAccountInvoice
  ) => {
    window.open(value.viewInvoice);
  };

  useEffect(() => {
    loadBillingAccounts();
  }, [loadBillingAccounts]);

  const BladeContent = () => {
    if (bladeLoader) {
      return <Loader loading={bladeLoader} />;
    }
    return (
      <Container className={styles.dataGridContainer}>
        <Divider title="Invoice details" />
        <DataGrid
          columns={billingAccountInvoicesDetails}
          data={selectedInvoice}
        />
        <Divider title="Invoice charges details" />
        {selectedInvoice?.invoiceItems.map((invoice) => {
          return (
            <Container key={`Invoice ${invoice.id}`}>
              <TypoGraph
                variant="body1"
                content={invoice.description || "No description available"}
              />
              <TypoGraph
                variant="body2"
                content={formatAmount(invoice.amount)}
              />

              <Container
                sx={{
                  gridTemplateColumns: "auto auto",
                  justifyContent: "space-between",
                  padding: "5px 10px",
                }}
              ></Container>
              <Divider />
            </Container>
          );
        })}
      </Container>
    );
  };

  const BladeFooter = () => {
    return (
      <Container className={styles.actionContainer}>
        <Button
          id={t.VIEW_IN_STRIPE}
          disabled={!selectedInvoice?.viewInvoice}
          variant="outlined"
          label={
            selectedInvoice?.viewInvoice
              ? t.VIEW_IN_STRIPE
              : "View in Stripe Not Available"
          }
          onClick={() => {
            window.open(selectedInvoice?.viewInvoice, "_blank", "noreferrer");
          }}
        />
        <Button
          id={t.DOWNLOAD_INVOICE_PDF}
          disabled={!selectedInvoice?.invoicePdf}
          color="primary"
          label={
            selectedInvoice?.invoicePdf
              ? t.DOWNLOAD_INVOICE_PDF
              : "PDF Not Available"
          }
          loading={false}
          onClick={() => {
            window.open(selectedInvoice?.invoicePdf, "_blank", "noreferrer");
          }}
        />
      </Container>
    );
  };

  const handleQuickSearch = (value: string) => {
    setSearchValue(value);
    loadBillingAccounts(0, 10);
  };

  return (
    <Container>
      <HeaderBar title={t.MY_BYLLING_ACCOUNTS} />
      <TableComponent
        title={t.BILLING_ACCOUNTS}
        data={billingAccountInvoices || []}
        totalCount={billingAccountInvoices.length}
        columns={billingAccountInvoicesColumns}
        loading={loading}
        handlePagination={(currentPage, pageSize) =>
          loadBillingAccounts(currentPage, pageSize)
        }
        searchValue={searchValue}
        handleQuickSearch={handleQuickSearch}
        serverPaginationMode={false}
      />
      <Blade
        show={showBlade}
        title={!bladeLoader ? t.BILLING_ACCOUNT_INVOICE_DETAILS : ""}
        handleClose={() =>
          navigate(`settings/mybilling/${billingAccountId}/invoices`)
        }
        content={<BladeContent />}
        footerContent={!bladeLoader && <BladeFooter />}
      />
    </Container>
  );
};

export default BillingAccountInvoices;
