import {
  Blade,
  Button,
  Container,
  CustomDivider,
  Loader,
  Toast as toast,
  PropertyForm,
  SearchMyAdressComponent,
  TypoGraph,
  Icon,
} from "components";
import strings from "strings";
import { useEffect, useState } from "react";
import styles from "../paymentsList.module.scss";

import {
  DropdownOptions,
  INewReceivablePaymentRequest,
  IPaymentMethod,
} from "interfaces";
import {
  DatePickerFormField,
  DropdownFormField,
  InputFormField,
  SwitchFormField,
} from "components/hookForm";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  getCompaniesForUser,
  getPaymentMethodsForPaymentRequestForGivenCompany,
  getReceivableAccountsForCompany,
  getUsStates,
  newReceivablePaymentRequest,
} from "api";
import regex from "regex";
import {
  amountIsValid,
  dateRangeValidator,
  formatDate,
} from "tools/validators";
import Decimal from "decimal.js";
import {
  boolOptions,
  commissionEarnedRoles,
  earnestBroker,
  securityDepositRoles,
} from "constants/dropdownOptions";
import { isDate } from "moment/moment";
import { formatDateToMMDDYYYY } from "tools/format";
import {
  DATE_FORMAT_YYYY_MM_DD,
  MONT_YEAR_FORMAT,
  todayDate,
  tomorrowDate,
} from "constants/datePicker";
import PaymentMethodSelection from "components/paymentMethodSelection";
import colors from "theme/colors";
import Dropzone from "react-dropzone";
import { convertFileToBase64 } from "tools/base64";
import { prFeeOptions } from "constants/prFeeOptions";
import { IRecurringPaymentRequest } from "interfaces/INewReceivablePaymentRequest";
import { RequestPaymentCustomRecurrenceModal } from "./requestPaymentCustomRecurrenceModal";
import {
  endDateOptions,
  frequencyOptions,
  weekdayOptions,
} from "constants/recurringPayment";
import BaseDialog from "components/dialog/baseDialog";
import { paymentMethods } from "constants/paymentMethods";

interface IPaymentBladeProps {
  handleClose: () => void;
  handleDataChange: () => void;
  typeId: string | undefined;
  paymentTypeName: string | undefined;
}

const RequestPaymentBladeComponent = ({
  handleClose,
  handleDataChange,
  typeId,
  paymentTypeName,
}: IPaymentBladeProps) => {
  const [bladeLoader, setBladeLoader] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const [availableCompanies, setAvailableCompanies] =
    useState<DropdownOptions[]>();
  const [states, setStates] = useState<DropdownOptions[]>();
  const methods = useForm<INewReceivablePaymentRequest>({
    reValidateMode: "onChange",
  });
  const [closeDate, setCloseDate] = useState<Date>(new Date());
  const [bindDate, setBindDate] = useState<Date>(new Date());
  const [prDueDate, setPrDueDate] = useState<Date>(new Date());

  const [selectedPeriodDate, setSelectedPeriodDate] = useState<Date>(
    new Date()
  );
  const [recurringStartDate, setRecurringStartDate] = useState<Date>(
    new Date()
  );
  const [recurringEndDate, setRecurringEndDate] = useState<Date>(
    new Date(new Date().setDate(todayDate.getDate() + 1))
  );
  const [isCustomModalOpen, setIsCustomModalOpen] = useState<boolean>(false);
  const [isEndDateModalOpen, setIsEndDateModalOpen] = useState<boolean>(false);

  const { getValues, control, setValue, handleSubmit, watch, reset } = methods;

  const xtraFundsWatch = useWatch({
    control,
    name: "xtraFunds",
    defaultValue: getValues("xtraFunds") || "0",
  });

  useEffect(() => {
    if (getValues("xtraFunds") === "0") {
      setValue("xfundamountInCents", undefined);
      setValue("xtraFunds", undefined);
    }
  }, [xtraFundsWatch]);
  const selectedCompany = watch("companyId");
  const [
    availablePaymentMethodsForCurrentCompany,
    setAvailablePaymentMethodsForCurrentCompany,
  ] = useState<IPaymentMethod[]>();
  const [selectedPaymentMethodsCount, setSelectedPaymentMethodsCount] =
    useState(1);
  const [attachedFile, setAttachedFile] = useState<File>();
  const MAX_ATTACHMENT_SIZE = 6000000;

  const isRecurringPayment: boolean = getValues("frequency") !== "NOTREPEAT";
  const [receivableAccountOptions, setReceivableAccountOptions] =
    useState<DropdownOptions[]>([]);

  const handlePaymentMethodSelectionCount = (
    formData: INewReceivablePaymentRequest
  ): boolean => {
    const foundPaymentMethod = availablePaymentMethodsForCurrentCompany?.some(
      (payment) => {
        return formData[payment.name];
      }
    );
    setSelectedPaymentMethodsCount(foundPaymentMethod ? 1 : 0);
    return !!foundPaymentMethod;
  };
  const onSubmit = async (formData: INewReceivablePaymentRequest) => {
    // Stop execution if handlePaymentMethodSelectionCount returns false (no payment method selected).
    if (!handlePaymentMethodSelectionCount(formData)) {
      return;
    }
    setButtonLoader(true);
    fillRequiredFieldsByPaymentType(formData);
    formData.amountInCents = new Decimal(formData.amount || 0)
      .times(100)
      .toNumber();
    formData.closedate = formData.closedate ? formData.closedate : "";
    formData.binddate = formData.binddate ? formData.binddate : "";
    formData.buyerName = formData.buyerFirstName;
    formData.paymentType = typeId !== undefined ? parseInt(typeId, 10) : 0;
    formData.trrole = Number(formData.trrole);
    formData.emholder = Number(formData.emholder);
    formData.emamountInDollars = new Decimal(formData.emamountInDollars || 0)
      .times(100)
      .toNumber();
    formData.add1 = formData.add1 ? formData.add1 : "";
    formData.add2 = formData.add2 ? formData.add2 : "";
    formData.city = formData.city ? formData.city : "";
    formData.state = formData.state ? formData.state : "";
    formData.postalCode = formData.postalCode ? formData.postalCode : "";
    const paymentMethodsRequest: IPaymentMethod[] = [];
    availablePaymentMethodsForCurrentCompany?.forEach((payment) => {
      if (formData[payment.name]) {
        paymentMethodsRequest.push(payment);
      }
      delete formData[payment.name];
    });
    formData.allowedPaymentMethods = paymentMethodsRequest;
    if (attachedFile) {
      const base64 = await convertFileToBase64(attachedFile);
      formData.attachment = base64
        ?.toString()
        ?.replace("data:application/pdf;base64,", "");

      formData.attachment = formData.attachment
        ?.toString()
        ?.replace("data:application/octet-stream;base64,", "");

      formData.attachmentFileName = attachedFile.name;
    } else {
      formData.attachment = "";
      formData.attachmentFileName = "";
    }
    if (getValues("dueDateSelect")) {
      formData.prDueDate = formData.prDueDate;
      formData.feeValue = parseInt(
        formData.feeValue.toString().replace(/\./g, "")
      );
    } else {
      formData.prDueDate = null;
    }

    if (isRecurringPayment) {
      let weekdaysToRepeat = formData.weekdays;
      let endDate =
        getValues("endDateSelect") === "ENDSONDATE"
          ? getValues("recurringEndDate")
          : undefined;
      let maxOccurrences =
        getValues("recurringEndCondition") === "OCCURRENCES"
          ? getValues("maximumOccurrences")
          : undefined;
      let recurringPaymentRequest: IRecurringPaymentRequest = {
        startDate: formData.recurringStartDate,
        frequency: formData.frequency,
        customRepetitionNumber: formData.customNumber,
        customRepetitionType: formData.customType,
        customWeekdaysToRepeat: weekdaysToRepeat,
        endDate: endDate,
        maximumOccurrences: maxOccurrences,
      };
      formData.recurringPayment = recurringPaymentRequest;
    }

    if (
      !formData.allowedPaymentMethods.find(
        (pm) => pm.id == paymentMethods.WIRE_TRANSFER
      )
    ) {
      delete formData.receivableAccountId;
    }

    delete formData.recurringStartDate;
    delete formData.recurringEndDate;
    delete formData.recurringEndCondition;
    delete formData.frequency;
    delete formData.customNumber;
    delete formData.customType;
    delete formData.maximumOccurrences;
    delete formData.recurringEndCondition;
    delete formData.weekdays;
    delete formData.dueDateSelect;
    delete formData.buyerFirstName;
    delete formData.amount;
    delete formData.endDateSelect;
    formData.xfundamountInCents = new Decimal(formData.xfundamountInCents || 0)
      .times(100)
      .toNumber();
    newReceivablePaymentRequest(formData)
      .then((response) => {
        handleDataChange();
        handleClose();
        const buyerName = formData.buyerName + " " + formData.buyerLastName;
        toast({
          title: `Request for ${
            paymentTypeName === "Commission Earned"
              ? "Commission Check"
              : paymentTypeName
          } has been sent to ${buyerName}`,
        });
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };

  function validatAndSetFile(file: File) {
    if (file.size > MAX_ATTACHMENT_SIZE) {
      toast({
        type: "error",
        title: "The file size can't be more than 6MB",
        autoClose: 1500,
      });
      return;
    }
    setAttachedFile(file);
  }
  const fillRequiredFieldsByPaymentType = (
    formData: INewReceivablePaymentRequest
  ) => {
    switch (typeId !== undefined ? parseInt(typeId, 10) : 0) {
      case 8:
      case 7:
      case 0:
      case 5:
      case 10:
      case 11:
      case 14:
        formData.saname =
          formData.buyerFirstName + " " + formData.buyerLastName;
        formData.laname =
          formData.buyerFirstName + " " + formData.buyerLastName;
        break;
      case 9:
        formData.saname =
          formData.buyerFirstName + " " + formData.buyerLastName;
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const feeValueElement = document.getElementById("feeValue");
    if (feeValueElement) {
      feeValueElement.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [watch("dueDateSelect"), watch("feeType"), watch("prDueDate")]);

  useEffect(() => {
    const wireTransferElement = document.getElementById("receivableAccountId");
    if (wireTransferElement) {
      wireTransferElement.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [watch("Wire Transfer")]);

  useEffect(() => {
    const recurringSelectElement = document.getElementById("frequency");
    if (recurringSelectElement) {
      recurringSelectElement.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [
    watch("recurringStartDate"),
    watch("recurringEndDate"),
    watch("frequency"),
    watch("recurringEndCondition"),
    watch("customType"),
    watch("customNumber"),
    watch("weekdays"),
    watch("maximumOccurrences"),
    watch("endDateSelect"),
    isEndDateModalOpen,
    isCustomModalOpen,
  ]);

  useEffect(() => {
    setValue("feeValue", 0);
  }, [watch("feeType")]);

  useEffect(() => {
    if (getValues("recurringEndDate")) {
      setValue("endDateSelect", "ENDSONDATE");
    }
  }, [watch("recurringEndDate")]);

  useEffect(() => {
    // Get the US states and the available companies for the current user, executes 1 time when blade opens.
    setBladeLoader(true);
    (async () => {
      try {
        const statesResponse = await getUsStates();
        const statesOptions = statesResponse.data.map((state) => {
          return {
            value: state.id,
            label: state.name,
          };
        });
        setStates(statesOptions);

        const companiesResponse = await getCompaniesForUser();
        const companiesOptions = companiesResponse.data.map((state) => {
          return {
            value: state.id,
            label: state.name,
          };
        });
        setAvailableCompanies(companiesOptions);
      } finally {
        setBladeLoader(false);
      }
    })();
  }, []);
  // When the available companies are loaded, updates the available payment methods.
  useEffect(() => {
    reset({
      companyId: selectedCompany,
      feeValue: 0,
      frequency: "NOTREPEAT",
      amount: 0,
      prDueDate: formatDateToMMDDYYYY(todayDate),
      recurringStartDate: formatDateToMMDDYYYY(todayDate),
      receivableAccountId: undefined,
    });

    if (selectedCompany) {
      getPaymentMethodsForPaymentRequestForGivenCompany(selectedCompany).then(
        (response) => {
          setAvailablePaymentMethodsForCurrentCompany(response.data);
          let companyHasWireTransferEnabled =
            response.data.find((pm) => pm.id == paymentMethods.WIRE_TRANSFER) !=
            undefined;
          if (companyHasWireTransferEnabled) {
            getReceivableAccountsForCompany(selectedCompany).then(
              (response) => {
                let accountOptions = response.data
                  .filter((account) => account.hasWiringInstructions)
                  .map((account) => {
                    return {
                      value: account.id,
                      label: account.accountName,
                    };
                  });
                setReceivableAccountOptions(accountOptions);
                if (accountOptions.length == 1) {
                  setValue("receivableAccountId", accountOptions[0].value);
                }
              }
            );
          }
        }
      );
    }
  }, [selectedCompany]);

  const paymentTypeDetails = () => {
    switch (typeId !== undefined ? parseInt(typeId, 10) : 0) {
      case 0:
      case 7:
      case 8:
      case 9:
      case 10:
      case 14:
        return (
          <>
            <InputFormField
              name="buyerPhone"
              label={strings.PHONE_NUMBER}
              type="number"
              required
            />
            <InputFormField name="name3" label={strings.SELLER_NAME} />
            <InputFormField name="fileNumber" label={strings.FILE_NUMBER} />
            <InputFormField
              name="titleCoContact"
              label={"Title Co. Contact"}
              maxLength={35}
            />
            <CustomDivider title={strings.PROPERTY_DETAILS} />
            <Container className={styles.searchMyAddress}>
              <SearchMyAdressComponent />
            </Container>
            <PropertyForm
              customLabels={{ add1: "Address 1" }}
              statesOptions={states}
              hideSearch={true}
              customStyle={"rowWithoutGapPropertyContainer"}
            />
          </>
        );
      case 2:
        return (
          <>
            <DropdownFormField
              name="trrole"
              label="I am the"
              options={commissionEarnedRoles}
              required
            />

            <InputFormField
              name="closeattorney"
              label="Closing Attorney Name"
            />

            <DropdownFormField
              name="emholder"
              label="Who was holding Earnest Money?"
              options={earnestBroker}
              required
            />

            <InputFormField
              name="emamountInDollars"
              label="Earnest Money Amount"
              maxLength={13}
              defaultValue={
                getValues("emamountInDollars")
                  ? getValues("emamountInDollars")
                  : "0"
              }
              currency
            />
            <DatePickerFormField
              name={"closedate"}
              onChange={(e: React.ChangeEvent<any> | null) => {
                isDate(e) && setCloseDate(e);
                isDate(e) && setValue("closedate", formatDateToMMDDYYYY(e));
              }}
              minDate={todayDate}
              defaultValue={formatDateToMMDDYYYY(todayDate)}
              inputFormat={DATE_FORMAT_YYYY_MM_DD}
              value={
                getValues("closedate") !== undefined
                  ? new Date(formatDate(getValues("closedate")! as string))
                  : closeDate
              }
              label={"Closing Date"}
            />
            <CustomDivider title={strings.PROPERTY_DETAILS} />
            <Container className={styles.searchMyAddress}>
              <SearchMyAdressComponent />
            </Container>
            <PropertyForm
              customLabels={{ add1: "Address 1" }}
              statesOptions={states}
              hideSearch={true}
              customStyle={"rowWithoutGapPropertyContainer"}
            />
          </>
        );
      case 3:
        return (
          <>
            <CustomDivider title="Transaction Info" />
            <Container className={styles.transactionInfoContainer}>
              <DropdownFormField
                name={"curMonth"}
                label={"Funds for current month?"}
                options={boolOptions}
                required
              ></DropdownFormField>
              <DropdownFormField
                name={"rentLate"}
                label={"Paying rent late?"}
                options={boolOptions}
                required
              />
              <DropdownFormField
                name={"xtraFunds"}
                label={"Additional funds included?"}
                options={boolOptions}
                required
              />
              {getValues("xtraFunds") === "1" && (
                <>
                  <InputFormField
                    name="xfundamountInCents"
                    label="Additional amount"
                    maxLength={13}
                    defaultValue={
                      getValues("xfundamountInCents")
                        ? getValues("xfundamountInCents")
                        : "0"
                    }
                    currency
                    validate={(textFieldValue: string) =>
                      amountIsValid(textFieldValue)
                    }
                    required
                  />
                  <InputFormField
                    name="xfundNote"
                    label="Explanation"
                    required
                  />
                </>
              )}
            </Container>
            <CustomDivider title="Property Details" />
            <Container className={styles.searchMyAddress}>
              <SearchMyAdressComponent />
            </Container>
            <PropertyForm
              customLabels={{ add1: "Address 1" }}
              statesOptions={states}
              hideSearch={true}
              customStyle={"rowWithoutGapPropertyContainer"}
            />
          </>
        );
      case 1:
        return (
          <>
            <DropdownFormField
              name={"trrole"}
              label={"I am the"}
              options={securityDepositRoles}
              required
            ></DropdownFormField>
            <DatePickerFormField
              name={"closedate"}
              onChange={(e: React.ChangeEvent<any> | null) => {
                isDate(e) && setCloseDate(e);
                isDate(e) && setValue("closedate", formatDateToMMDDYYYY(e));
              }}
              minDate={todayDate}
              defaultValue={formatDateToMMDDYYYY(todayDate)}
              inputFormat={DATE_FORMAT_YYYY_MM_DD}
              value={
                getValues("closedate") !== undefined
                  ? new Date(formatDate(getValues("closedate")! as string))
                  : closeDate
              }
              label={"Lease Start Date"}
              validate={() =>
                dateRangeValidator(
                  getValues("closedate")! as string,
                  getValues("binddate")! as string
                )
              }
            />
            <DatePickerFormField
              name={"binddate"}
              onChange={(e: React.ChangeEvent<any> | null) => {
                isDate(e) && setBindDate(e);
                isDate(e) && setValue("binddate", formatDateToMMDDYYYY(e));
              }}
              minDate={todayDate}
              defaultValue={formatDateToMMDDYYYY(todayDate)}
              inputFormat={DATE_FORMAT_YYYY_MM_DD}
              value={
                getValues("binddate") !== undefined
                  ? new Date(formatDate(getValues("binddate")! as string))
                  : bindDate
              }
              label={"Lease End Date"}
            />
            <DropdownFormField
              name={"petdep"}
              label={"Pet deposit included?"}
              options={boolOptions}
              required
            ></DropdownFormField>
            <InputFormField name="laname" label="Listing agent name" required />
            <InputFormField name="saname" label="Leasing name" />
            <InputFormField name="name1" label="Tenant 1" required />
            <InputFormField name="name2" label="Tenant 2" />
            <InputFormField name="name3" label="Landlord/owner 1" />
            <InputFormField name="name4" label="Landlord/owner 2" />
            <CustomDivider title={strings.PROPERTY_DETAILS} />
            <Container className={styles.searchMyAddress}>
              <SearchMyAdressComponent />
            </Container>
            <PropertyForm
              customLabels={{ add1: "Address 1" }}
              statesOptions={states}
              hideSearch={true}
              customStyle={"rowWithoutGapPropertyContainer"}
            />
          </>
        );
      case 5:
        return (
          <>
            <CustomDivider title={"Transaction Info"} />
            <InputFormField
              name="otherptype"
              label="Payment Description"
              required
            />
            <br></br>
          </>
        );
      case 11:
        return (
          <>
            <CustomDivider title="Invoice Details" />
            <Container className={styles.singleInputContainer}>
              <InputFormField name="invoiceNumber" label="Invoice #" required />
            </Container>
            <br></br>
          </>
        );
      case 12:
        return (
          <>
            <CustomDivider title="Payment Details" />
            <InputFormField
              name="crrAgentName"
              label="CRR Agent's Name"
              required
            />
            <InputFormField name="mlsNumber" label="MLS Number" maxLength={8} />
            <DatePickerFormField
              name={"closedate"}
              onChange={(e: React.ChangeEvent<any> | null) => {
                isDate(e) && setCloseDate(e);
                isDate(e) && setValue("closedate", formatDateToMMDDYYYY(e));
              }}
              minDate={todayDate}
              defaultValue={formatDateToMMDDYYYY(todayDate)}
              inputFormat={DATE_FORMAT_YYYY_MM_DD}
              value={
                getValues("closedate") !== undefined
                  ? new Date(formatDate(getValues("closedate")! as string))
                  : closeDate
              }
              label={"Closing Date"}
            />
          </>
        );
      case 13:
        return (
          <>
            <CustomDivider title="Payment Details" />
            <InputFormField
              name="realEstateLicense"
              label="Real State License #"
              maxLength={12}
              required
            />
            <InputFormField name="nickname" label="Nickname" />
            <DatePickerFormField
              name={"coveringDate"}
              onChange={(e: React.ChangeEvent<any> | null) => {
                isDate(e) && setSelectedPeriodDate(e);
                isDate(e) && setValue("coveringDate", formatDateToMMDDYYYY(e));
              }}
              minDate={todayDate}
              defaultValue={formatDateToMMDDYYYY(todayDate)}
              inputFormat={MONT_YEAR_FORMAT}
              views={["month"]}
              value={
                getValues("coveringDate") !== undefined
                  ? new Date(formatDate(getValues("coveringDate")! as string))
                  : selectedPeriodDate
              }
              label={"Which period is this covering?"}
            />
          </>
        );
      default:
        break;
    }
  };

  const Attachment = () => {
    if (attachedFile) {
      return (
        <Container key={attachedFile?.name} className={styles.filePreview}>
          <Container className={styles.fileNameContainer}>
            <Icon name="icon_uploadSuccess" />
            <TypoGraph
              variant="caption"
              content={attachedFile?.name}
              className={styles.text}
            />
          </Container>
          <Container className={styles.cancelIcon}>
            <Icon
              iconClassName={styles.fileCancelIcon}
              name="icon_filecancel"
              iconButton
              onClick={() => setAttachedFile(undefined)}
            />
          </Container>
        </Container>
      );
    }
    return (
      <Dropzone
        accept={{ "application/pdf": [] }}
        multiple={false}
        onDrop={(file) => validatAndSetFile(file[0])}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={styles.dropzoneContainer}>
            <div
              {...getRootProps({
                className: "dropzone",
                onDrop: (event) => event.stopPropagation(),
              })}
            >
              <input {...getInputProps()} />
              <Container className={styles.fileUploadText}>
                <Icon name="icon_fileupload" size={25} />
                <TypoGraph
                  content={"Upload an attachment (max size 6MB)"}
                  color="#666666"
                />
              </Container>
            </div>
          </div>
        )}
      </Dropzone>
    );
  };

  const DueDate = () => {
    return (
      <Container className={styles.selectDueDateContainer}>
        <Container className={styles.datePickerContainer}>
          <DatePickerFormField
            disabled={!getValues("dueDateSelect")}
            required={!getValues("dueDateSelect")}
            name={"prDueDate"}
            onChange={(e: React.ChangeEvent<any> | null) => {
              isDate(e) && setPrDueDate(e);
              isDate(e) && setValue("prDueDate", formatDateToMMDDYYYY(e));
            }}
            minDate={todayDate}
            defaultValue={formatDateToMMDDYYYY(todayDate)}
            inputFormat={DATE_FORMAT_YYYY_MM_DD}
            value={
              getValues("prDueDate") !== undefined
                ? new Date(formatDate(getValues("prDueDate")! as string))
                : prDueDate
            }
            label={"Due date"}
          />
        </Container>
        <Container className={styles.feeContainer}>
          <InputFormField
            disabled={!getValues("dueDateSelect")}
            label={`Late Fee`}
            className={styles.feeValue}
            name={`feeValue`}
            maxLength={getValues("feeType") == "FLAT" ? 13 : 2}
            required={getValues("dueDateSelect")}
            type={
              getValues("feeType") == prFeeOptions.PERCENTAGE
                ? "number"
                : undefined
            }
            currency={getValues("feeType") == "FLAT" ? true : false}
          />
          <DropdownFormField
            disabled={!getValues("dueDateSelect")}
            options={[
              { label: "USD", value: prFeeOptions.FLAT },
              { label: "%", value: prFeeOptions.PERCENTAGE },
            ]}
            name={"feeType"}
            className={styles.feeOptions}
            label={""}
            required={getValues("dueDateSelect")}
          />
        </Container>

        <SwitchFormField name="dueDateSelect" />
      </Container>
    );
  };

  const RecurringPayment = () => {
    return (
      <Container>
        <DropdownFormField
          options={frequencyOptions}
          name={"frequency"}
          label={"Frequency"}
          defaultValue={"NOTREPEAT"}
          onChange={(value) => {
            setValue("frequency", value.target.value);
            if (value.target.value == "CUSTOM") {
              setIsCustomModalOpen(true);
            }
            if (value.target.value == "NOTREPEAT") {
              setValue("endDateSelect", undefined);
            }
          }}
        />
        <Container className={styles.selectRecurringContainer}>
          <Container className={styles.datePickerContainer}>
            <DatePickerFormField
              required={isRecurringPayment}
              name={"recurringStartDate"}
              disabled={!isRecurringPayment}
              onChange={(e: React.ChangeEvent<any> | null) => {
                isDate(e) && setRecurringStartDate(e);
                isDate(e) &&
                  setValue("recurringStartDate", formatDateToMMDDYYYY(e));
              }}
              minDate={todayDate}
              defaultValue={formatDateToMMDDYYYY(todayDate)}
              inputFormat={DATE_FORMAT_YYYY_MM_DD}
              value={
                getValues("recurringStartDate") !== undefined
                  ? new Date(
                      formatDate(getValues("recurringStartDate")! as string)
                    )
                  : recurringStartDate
              }
              label={"Start date"}
            />
          </Container>
          <DropdownFormField
            disabled={!isRecurringPayment}
            options={endDateOptions}
            name={"endDateSelect"}
            label={"End Date"}
            onChange={(value) => {
              setValue("endDateSelect", value.target.value);
              if (value.target.value == "ENDSONDATE") {
                setIsEndDateModalOpen(true);
              }
            }}
          />
        </Container>
      </Container>
    );
  };
  const CustomRecurringPaymentSummary = () => {
    let customFrequency = watch("customType");
    let customNumber = watch("customNumber");
    let weekdays = watch("weekdays");
    if (
      watch("frequency") != "CUSTOM" ||
      !customNumber ||
      !customFrequency ||
      !isRecurringPayment ||
      isCustomModalOpen
    ) {
      return <></>;
    }

    let repetitionPattern = "";
    let repetitionWeekdays = "";
    if (customFrequency === "DAY") {
      if (customNumber == "1") {
        repetitionPattern = "Daily";
      } else {
        repetitionPattern = `every ${customNumber} Days`;
      }
    }
    if (customFrequency === "WEEK" && weekdays && weekdays.length > 0) {
      let weekdaysToRepeat = weekdays;
      let weekdaysString = weekdayOptions
        .filter((weekday) => weekdaysToRepeat.includes(weekday.value))
        .map((weekday) => weekday.label);
      repetitionWeekdays = `on ${weekdaysString.join(", ")}`;
      if (customNumber == "1") {
        repetitionPattern = "Weekly";
      } else {
        repetitionPattern = `every ${customNumber} Weeks`;
      }
    }
    if (customFrequency === "MONTH") {
      if (customNumber == "1") {
        repetitionPattern = "Monthly";
      } else {
        repetitionPattern = `every ${customNumber} Months`;
      }
    }

    return (
      <>
        <TypoGraph
          content={`Repeats ${repetitionPattern} ${repetitionWeekdays} starting on ${formatDate(
            watch("recurringStartDate")
          )}`}
        />
        <Container
          sx={{
            display: "grid",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            label="Change custom recurrence"
            variant="text"
            onClick={() => setIsCustomModalOpen(true)}
          />
        </Container>
      </>
    );
  };
  const BladeContent = () => {
    if (bladeLoader) {
      return <Loader loading={bladeLoader} />;
    }
    return (
      <FormProvider {...methods}>
        <Container className={styles.bladeContentDetails}>
          <CustomDivider title={strings.COMPANY} />
          <DropdownFormField
            options={availableCompanies}
            name="companyId"
            label={strings.COMPANY}
            required
          />
          <CustomDivider title={strings.PAYER_DETAILS} />
          <Container className={styles.twoFieldsContainer}>
            <InputFormField name="buyerFirstName" label="First Name" required />
            <InputFormField name="buyerLastName" label="Last Name" required />
          </Container>
          <InputFormField
            name="buyerEmail"
            label={strings.EMAIL_ADDRESS}
            pattern={{
              value: regex.EMAIL_REGEX_PATTERN,
              message: strings.PLEASE_ENTER_A_VALID_EMAIL,
            }}
            required
          />
          {paymentTypeDetails()}
          <CustomDivider title={"Payment Methods"} />
          <PaymentMethodSelection
            availablePaymentMethods={availablePaymentMethodsForCurrentCompany}
          />
          <span id="receivableAccountId"></span>
          {watch("Wire Transfer") && (
            <DropdownFormField
              name={"receivableAccountId"}
              label={"Receivable Account for wire instructions"}
              options={receivableAccountOptions}
              required
            />
          )}
          {selectedPaymentMethodsCount === 0 && (
            <TypoGraph
              color={colors.error}
              content={"At least one payment method is required."}
            />
          )}
          <Container className={styles.twoFieldsContainer}>
            <InputFormField
              name="amount"
              label={strings.PAYMENT_AMOUNT}
              maxLength={13}
              defaultValue={getValues("amount") ? getValues("amount") : "0"}
              currency={true}
              validate={(textFieldValue: string) =>
                amountIsValid(textFieldValue)
              }
              required
            />
          </Container>
          <CustomDivider title={"Due date"} />
          {
            //By now, we are going to disable the Due Date for recurring payments, as it is a static date.
            !isRecurringPayment ? (
              <DueDate></DueDate>
            ) : (
              <TypoGraph content="Due date can not be set for a recurring request." />
            )
          }
          <CustomDivider title={"Note and Attachment"} />
          <Attachment />
          <Container>
            <InputFormField
              name="prNote"
              type="text"
              multiline={true}
              label={""}
              placeholder="Note..."
              maxLength={250}
            />
          </Container>
          <CustomDivider title={"Recurring Request"} />
          <RecurringPayment />
          <CustomRecurringPaymentSummary />
        </Container>
      </FormProvider>
    );
  };

  const BladeFooter = () => {
    return (
      <Container className={styles.singleButtonBladeFooterContainer}>
        <Button
          id={strings.SEND}
          label={strings.SEND_PAYMENT_REQUEST}
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={buttonLoader}
          disabled={bladeLoader}
        />
      </Container>
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <RequestPaymentCustomRecurrenceModal
          open={isCustomModalOpen}
          handleClose={() => {
            setIsCustomModalOpen(false);
            setValue("frequency", "NOTREPEAT");
            setValue("endDateSelect", undefined);
          }}
          handleSuccess={() => {
            setIsCustomModalOpen(false);
          }}
        />

        <BaseDialog
          title={"Select End Date"}
          open={isEndDateModalOpen}
          handleClose={() => {
            setIsEndDateModalOpen(false);
            setValue("endDateSelect", undefined);
          }}
          content={
            <Container sx={{ width: "400px" }}>
              <DatePickerFormField
                required={isRecurringPayment}
                name={"recurringEndDate"}
                onChange={(e: React.ChangeEvent<any> | null) => {
                  isDate(e) && setRecurringEndDate(e);
                  isDate(e) &&
                    setValue("recurringEndDate", formatDateToMMDDYYYY(e));
                }}
                minDate={tomorrowDate}
                defaultValue={formatDateToMMDDYYYY(tomorrowDate)}
                inputFormat={DATE_FORMAT_YYYY_MM_DD}
                value={
                  getValues("recurringEndDate") !== undefined
                    ? new Date(
                        formatDate(getValues("recurringEndDate")! as string)
                      )
                    : recurringEndDate
                }
                label={"End Date"}
              />
            </Container>
          }
          primaryBtnProps={{
            label: "Done",
            onClick: () => {
              setIsEndDateModalOpen(false);
            },
          }}
          secondaryBtnProps={{
            label: "Cancel",
            onClick: () => {
              setValue("endDateSelect", undefined);
              setIsEndDateModalOpen(false);
            },
          }}
        />
        <Blade
          show={true}
          title={!bladeLoader ? strings.SEND_PAYMENT_REQUEST : ""}
          handleClose={handleClose}
          content={<BladeContent />}
          footerContent={!bladeLoader && <BladeFooter />}
        />
      </FormProvider>
    </>
  );
};

export { RequestPaymentBladeComponent };
