import {
  Controller,
  useFormContext,
  Validate,
  ValidationRule,
} from "react-hook-form";
import { ITextFieldProps } from "components/controllers/input";
import React, { useState } from "react";
import { Box, TextField } from "@mui/material";

export interface IInputFieldProps extends ITextFieldProps {
  name: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number | { value: number; message: string };
  max?: number | { value: number; message: string };
  min?: number | { value: number; message: string };
  pattern?: ValidationRule<RegExp> | undefined;
  defaultValue?: string | number | undefined;
  validate?: Validate<any> | Record<string, Validate<any>> | undefined;
  disabled?: boolean;
  type?: "password" | "number" | "text" | "numericOnly";
  hideableText?: boolean;
}

const InputColorFormField: React.FC<IInputFieldProps> = (props) => {
  const {
    name,
    required = false,
    maxLength,
    minLength,
    max,
    min,
    pattern,
    defaultValue,
    validate,
    currency,
    disabled,
    hideableText = false,
    type = undefined,
  } = props;
  const { control, setValue } = useFormContext();
  const isRequired = required ? "Field is Required" : undefined;
  const [hideText, setHideText] = useState<boolean>(hideableText);

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        required: isRequired,
        validate: validate,
        minLength: minLength,
        min: min,
        max: max,
        pattern: pattern,
      }}
      render={({
        field: { onChange, onBlur, value = "", ref },
        fieldState: { error },
      }) => {
        return (
          <Box display="flex" alignItems="center" gap={1} sx={{"margin-top": "16px", "margin-bottom": "8px"}}>
            <TextField
              type={"color"}
              variant="outlined"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              fullWidth
              error={Boolean(error)}
              helperText={error?.message}
              sx={{ width: 150 }} 
            />
            <TextField
              id={name}
              fullWidth
              value={value ? value : ""}
              disabled={disabled}
              inputProps={{
                maxLength: 7,
                minLength: 7
              }}
              onChange={(e: any) => {
                currency || props.type === "number"
                  ? setValue(name, e.value)
                  : onChange(e);
              }}
              onBlur={() => {
                if (hideableText) {
                  setHideText(true);
                }
                onBlur();
              }}
              onFocus={() => {
                setHideText(false);
              }}
              inputRef={ref}
              error={Boolean(error)}
              helperText={error?.message}
              {...props}
            />
          </Box>
        );
      }}
    />
  );
};

export default InputColorFormField;
