import React from "react";
import { Navigate, Route, RouteProps, Routes } from "react-router-dom";
import BillingAccountRoutes from "./billingAccountRoutes";
import ProtectedRoute from "./ProtectedRoute";
import PaymentRoutes from "./paymentsRoutes";
import CompaniesRoutes from "./companiesRoutes";
import SettingsRoutes from "./settingsRoutes";
import DisbursementRoutes from "./disbursementRoutes";
import Page404 from "pages/page404";
import Profile from "pages/profile";
import HelpScreen from "pages/help";
import Login from "pages/login";
import { PrivDependantRoute } from "./PrivDependantRoute";
import {
  Actions,
  IndividualPrivileges,
  Resources,
  defineUserPriv,
  userHasAnyPriv,
} from "tools/privilegeChecker";
import MyBillingRoutes from "./myBillingRoutes";
import ReportsRoutes from "./reportsRoutes";
import PaymentRequestRoutes from "./paymentRequestRoutes";
import WhiteLabelsRoutes from "./whiteLabelsRoutes";

interface IApplicationRoute extends RouteProps {
  isAuth: boolean;
  userPriv: number;
}

const ApplicationRoute: React.FunctionComponent<IApplicationRoute> = (
  props
) => {
  const { isAuth, userPriv } = props;
  return (
    <Routes>
      <Route path="/login" element={defineRedirect()} />
      <Route
        path="/reset-password/:resetId"
        element={<Login currentStep={2} resetPassword />}
      />
      <Route
        path="/payments/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <PrivDependantRoute
              requirements={{ action: Actions.READ, resource: Resources.ITEM }}
            >
              <PaymentRoutes />
            </PrivDependantRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/disbursements/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <PrivDependantRoute
              requirements={{
                action: Actions.READ,
                resource: Resources.DISBURSEMENT_PAYMENTS,
              }}
            >
              <DisbursementRoutes />
            </PrivDependantRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/paymentrequest/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <PrivDependantRoute
              requirements={{
                action: Actions.READ,
                resource: Resources.PAYMENT_REQUEST,
              }}
            >
              <PaymentRequestRoutes />
            </PrivDependantRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/companies/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <PrivDependantRoute
              requirements={{
                action: Actions.READ,
                resource: Resources.COMPANY,
              }}
            >
              <CompaniesRoutes userPriv={userPriv} />
            </PrivDependantRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/billingaccounts/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <BillingAccountRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/mybilling/*"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.MY_BILLING_ACCOUNTS,
            }}
          >
            <MyBillingRoutes />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/settings/whitelabels/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <WhiteLabelsRoutes />
            </ProtectedRoute>

        }
      />
      <Route
        path="/reports/*"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.REPORTS,
            }}
          >
            <ReportsRoutes />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/settings/*"
        element={
          <PrivDependantRoute
            requirements={{
              action: Actions.READ,
              resource: Resources.SETTINGS,
            }}
          >
            <SettingsRoutes isAuth={isAuth} userPriv={userPriv} />
          </PrivDependantRoute>
        }
      />
      <Route
        path="/help/*"
        element={
          <PrivDependantRoute
            requirements={{ action: Actions.READ, resource: Resources.HELP }}
          >
            <HelpScreen />
          </PrivDependantRoute>
        }
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default ApplicationRoute;
function defineRedirect(): React.ReactNode {
  if (defineUserPriv() <= 30) {
    return <Navigate to={"/paymentrequest"} />;
  }
  if (
    userHasAnyPriv([
      IndividualPrivileges.RECEIVABLES_APPROVER,
      IndividualPrivileges.RECEIVABLE_MANAGER,
    ])
  ) {
    return <Navigate to={"/payments"} />;
  }
  return <Navigate to={"/help"} />;
}
