import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IBillingAccountCustomerResponse } from "interfaces";
import {
  getBillingAccountCustomerInfo,
  updateCustomerIdForBillingAccount,
} from "api";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Card, Container, Icon, Toast as toast, TypoGraph } from "components";
import { InputFormField } from "components/hookForm";
import DialogActions from "@mui/material/DialogActions";
import Button from "components/button";
import { useNavigate } from "react-router-dom";
import colors from "theme/colors";
import strings from "strings";
import styles from "./updateCustomer.module.scss";

interface IUpdateCustomerIdDialog {
  open: boolean;
  handleClose: () => void;
  billingAccountId: number | string;
}

interface IFormData {
  customerId: string;
}

const UpdateCustomer: React.FC<IUpdateCustomerIdDialog> = (props) => {
  const { open, handleClose, billingAccountId } = props;
  const methods = useForm<any>();
  const navigate = useNavigate();
  const { handleSubmit, reset, setValue } = methods;
  const [customerInfo, setCustomerInfo] =
    useState<IBillingAccountCustomerResponse>({
      id: "",
      email: "",
      name: "",
      billingAccountId: 0,
    });
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderUpdate, setLoaderUpdate] = useState<boolean>(false);
  const getInfoCustomer = (data: IFormData) => {
    setLoader(true);
    getBillingAccountCustomerInfo(data.customerId)
      .then((response) => {
        setCustomerInfo(response.data);
      })
      .finally(() => setLoader(false));
  };

  const updateInfoCustomer = (data: IFormData) => {
    setLoaderUpdate(true);
    updateCustomerIdForBillingAccount(data.customerId, billingAccountId)
      .then((response) => {
        handleClose();
        cleanCustomerInfo();
        reset();
        toast({
          title: "Updated Successfully",
          subTitle: `id: ${billingAccountId}, name: ${response.data.name}`,
        });
        navigate("/settings/billingaccounts");
      })
      .finally(() => setLoaderUpdate(false));
  };

  function cleanCustomerInfo() {
    setCustomerInfo({ id: "", email: "", name: "", billingAccountId: 0 });
  }

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          cleanCustomerInfo();
          reset();
        }}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          {strings.UPDATE_CUSTOMER}
        </DialogTitle>
        <DialogContent>
          <Icon name={`icon_dialog_warning`} height={150} width={700} />
          <TypoGraph
            mt={2}
            mb={2}
            content={strings.UPDATE_CUSTOMER_DISCLAIMER}
            variant="body1"
            align="center"
          />
          <Container
            sx={{
              gridTemplateColumns: "3fr auto",
              gridGap: "1rem",
            }}
          >
            <InputFormField
              name={"customerId"}
              label={"Customer Id"}
              required={true}
              onChange={(e) => {
                setValue("customerId", e.target.value);
                if (!!customerInfo.id) {
                  cleanCustomerInfo();
                }
              }}
            />
            <Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Button
                size={"small"}
                id={"Check"}
                onClick={handleSubmit((data) => getInfoCustomer(data))}
                color={"info"}
                label={"Check"}
                loading={loader}
              />
            </Container>
          </Container>

          {!!customerInfo.id ? (
            <Card>
              <Container className={styles.customerDataContainer}>
                <TypoGraph
                  component="span"
                  color={colors.primary}
                  variant="body1"
                  content="Stripe id:"
                />
                <TypoGraph
                  link={true}
                  content={customerInfo.id}
                  sx={{ textDecoration: "underline", fontWeight: "bold" }}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_STRIPE_DASHBOARD}customers/${customerInfo.id}`
                    )
                  }
                />
              </Container>
              <Container className={styles.customerDataContainer}>
                <TypoGraph
                  component="span"
                  variant="body1"
                  color={colors.primary}
                  content="Email:"
                />
                <TypoGraph content={customerInfo?.email} />
              </Container>
              <Container className={styles.customerDataContainer}>
                <TypoGraph
                  component="span"
                  variant="body1"
                  color={colors.primary}
                  content="Name:"
                />
                <TypoGraph content={customerInfo.name} />
              </Container>
              {customerInfo.billingAccountId != -1 && (
                <Container className={styles.customerDataContainer}>
                  <TypoGraph
                    component="span"
                    variant="body1"
                    color={colors.primary}
                    content="BA:"
                  />
                  <TypoGraph
                    content={customerInfo.billingAccountId.toString()}
                  />
                </Container>
              )}{" "}
            </Card>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Container className={styles.customerActionContainer}>
            <Button
              id={"Cancel"}
              label="Cancel"
              variant="text"
              onClick={() => {
                handleClose();
                cleanCustomerInfo();
                reset();
              }}
            />

            <Button
              id={"update-customer"}
              onClick={handleSubmit((data) => updateInfoCustomer(data))}
              color={"primary"}
              label={"Update"}
              loading={loaderUpdate}
              disabled={!customerInfo.id}
            />
          </Container>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default UpdateCustomer;
