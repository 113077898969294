import { useNavigate } from "react-router";
import {
  Button,
  Card,
  Container,
  HeaderBar,
  Icon,
  TypoGraph,
} from "components";
import styles from "./settings.module.scss";
import strings from "strings";
import colors from "theme/colors";
import { Actions, Resources, userCan } from "tools/privilegeChecker";
import { useEffect, useState } from "react";
import { canViewReceivableAccounts } from "api";

interface ISettingsCardData {
  title: string;
  icon: string;
  caption: string;
  btnLabel: string;
  action: string;
  enabled: boolean;
  key: string;
}

const Settings = () => {
  const navigate = useNavigate();
  const [canViewReceivableAccountsCard, setCanViewReceivableAccountsCard] =
    useState<boolean>(false);
  useEffect(() => {
    canViewReceivableAccounts().then((response) =>
      setCanViewReceivableAccountsCard(response.data)
    );
  }, []);
  const listItem: ISettingsCardData[] = [
    {
      title: "Users",
      icon: "icon_users",
      caption: "Add, update, and delete users",
      btnLabel: "Manage Users",
      action: "users",
      enabled: userCan(Actions.READ, Resources.USER),
      key: "users",
    },
    {
      title: "Companies",
      icon: "icon_companies",
      caption: userCan(Actions.WRITE, Resources.COMPANY)
        ? "Add, update, and delete companies"
        : "View companies and Company Reports",
      btnLabel: userCan(Actions.WRITE, Resources.COMPANY)
        ? "Manage Companies"
        : "View Companies",
      action: "companies",
      enabled: userCan(Actions.READ, Resources.COMPANY),
      key: "companies",
    },
    {
      title: "Bank Accounts",
      icon: "icon_companies",
      caption: "Manage bank accounts",
      btnLabel: "Bank Accounts",
      action: "/settings/disbursementAccounts",
      enabled:
        userCan(Actions.READ, Resources.DISBURSEMENT_ACCOUNTS) ||
        (canViewReceivableAccountsCard &&
          userCan(Actions.READ, Resources.RECEIVABLES_ACCOUNTS)),
      key: "disbursementAccounts",
    },
    {
      title: "My Billing Accounts",
      icon: "icon_mybilling",
      caption: "Manage My Billing Accounts",
      btnLabel: "My Billing Accounts",
      action: "/settings/mybilling",
      enabled: userCan(Actions.READ, Resources.MY_BILLING_ACCOUNTS),
      key: "disbursementAccounts",
    },
    {
      title: "Billing Accounts",
      icon: "icon_companies",
      caption: "Manage Billing Accounts",
      btnLabel: "Billing Accounts",
      action: "/settings/billingaccounts",
      enabled: userCan(Actions.READ, Resources.BILLING_ACCOUNT),
      key: "disbursementAccounts",
    },
    {
      title: "Whitelabels",
      icon: "icon_companies",
      caption: "Add and update whitelabels",
      btnLabel: "Whitelabels",
      action: "/settings/whitelabels",
      enabled: userCan(Actions.READ, Resources.COMPANY),
      key: "whitelabels",
    },
  ];

  const CardComponent = (props: ISettingsCardData) => {
    const { title, icon, caption, btnLabel, action } = props;
    return (
      <Container>
        <Card sxContent={{ display: "grid", gridGap: "10px" }}>
          <Container className={styles.cardContentContainer}>
            <Icon name={icon} size={33} />
            <TypoGraph variant="h3" content={title} align="center" />
            <TypoGraph
              variant="body1"
              content={caption}
              align="center"
              color={colors.grey}
            />
            <Button
              id={btnLabel}
              variant="outlined"
              label={btnLabel}
              className={styles.redirectionBtn}
              onClick={() => navigate(action)}
            />
          </Container>
        </Card>
      </Container>
    );
  };

  return (
    <Container>
      <HeaderBar title={strings.SETTINGS} />
      <Container className={styles.settingsCardContainer}>
        {listItem
          .filter((i) => i.enabled)
          .map((item) => (
            <CardComponent {...item} />
          ))}
      </Container>
    </Container>
  );
};

export default Settings;
