import { Chip, Container, TypoGraph } from "components";
import { ITypoGraphProps } from "components/typograph";
import { ObjectAnyKeys } from "interfaces";
import currencyFormatter from "tools/currencyFormatter";
import styles from "./dataGrid.module.scss";
import colors from "theme/colors";

interface IDataGridColumns {
  name: string;
  label?: string;
  data?: string;
  labelTypoProps?: ITypoGraphProps;
  dataTypoProps?: ITypoGraphProps;
  messageIfEmpty?: string;
  hide?: (data: any) => boolean;
}

interface IDataGridProps {
  columns: IDataGridColumns[];
  data?: ObjectAnyKeys;
  hideIfEmpty?: boolean;
  greyColumns?: boolean;
}

const DataGrid = (props: IDataGridProps) => {
  const { columns, data, hideIfEmpty } = props;
  for (const key in data) {
    columns.forEach((ele, i) => {
      if (ele.name === key) {
        columns[i].data = data[key];
      }
    });
  }
  return (
    <>
      {columns
        .filter((c) => !!c.data || !hideIfEmpty)
        .map((item, index) => {
          if (item.label === "Amount" && item.data) {
            item.data = currencyFormatter(item.data);
          }
          if (item.hide && item.hide(data)) {
            return;
          }
          return (
            <Container
              key={`key-${index}`}
              className={styles.dataGridContainer}
            >
              {item.label && (
                <TypoGraph
                  color={props.greyColumns ? colors.grey : undefined}
                  content={item.label}
                  {...item.labelTypoProps}
                />
              )}
              {Array.isArray(item.data) ? (
                <Container className={styles.chipContainer}>
                  {item.data &&
                    item.data.map((item) => {
                      return (
                        <Chip label={item} color="default" variant="filled" />
                      );
                    })}
                </Container>
              ) : (
                <TypoGraph
                  color={item.label?.includes("Color")?item.data : undefined }
                  content={
                    item.data === "Commission Earned"
                      ? "Commission Check"
                      : item.data || item.messageIfEmpty
                  }
                  {...item.dataTypoProps}
                />
              )}
            </Container>
          );
        })}
    </>
  );
};

export default DataGrid;
