import React, { useCallback, useEffect, useState } from "react";
import { Container, Icon, TypoGraph } from "components";
import Button from "components/button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import strings from "strings";
import { dialogFields } from "constants/itemUpdateActions";
import colors from "theme/colors";
import { DropdownFormField, InputFormField } from "components/hookForm";
import { FormProvider, useForm } from "react-hook-form";
import { IPayment } from "interfaces";
import Endorsement from "pages/completed/partial/endorsement";

interface IUpdateItemDialogProps {
  open: boolean;
  handleClose: () => void;
  fields: dialogFields;
  selectedPayment?: IPayment;
}

const UpdateItemDialog: React.FC<IUpdateItemDialogProps> = (props) => {
  const { open, handleClose, fields, selectedPayment } = props;
  const [endorseRefresh, setEndorseRefresh] = useState<boolean>(false);
  const methods = useForm<any>();
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (selectedPayment) {
      reset({
        newStatusId: selectedPayment.statusId?.toString(),
        amountInDollars: selectedPayment.amount,
      });
    }
  }, [selectedPayment, reset]);

  const dialogContent = () => {
    return (
      <Container>
        <TypoGraph content={fields.subtitle} variant="caption" align="left" />

        {fields.iconPath && (
          <Icon name={fields.iconPath} height={150} width={552} />
        )}

        {!fields.dropdown ||
          (fields.dropdown?.options?.length === 0 && (
            <TypoGraph
              content={`${selectedPayment?.companyName} ${strings.HAS_NO_ACTIVE_ACCOUNTS}`}
              color={colors.error}
            />
          ))}
        {fields.dropdown && <DropdownFormField {...fields.dropdown} />}
        {fields.endorsement && fields.endorsement.payment?.accountId !== 0 && (
          <Container>
            {fields.endorsement.backImage && (
              <Endorsement {...fields.endorsement} refresh={endorseRefresh} />
            )}

            <TypoGraph content={fields.endorsementStatus} />
          </Container>
        )}
        {fields.textField && <InputFormField {...fields.textField} />}
        <TypoGraph variant="body2" content={fields.extraMessage} />
      </Container>
    );
  };

  const onSubmit = (formData: any) => {
    if (!fields.primaryBtn.onClick) {
      return;
    }
    fields.primaryBtn.onClick(formData);
    reset({
      newStatusId: formData.newStatusId,
      amountInDollars: formData.amountInDollars,
    });
  };

  const handleScroll = useCallback(() => {
    setEndorseRefresh(!endorseRefresh);
  }, [endorseRefresh]);

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
        maxWidth={fields.endorsement ? "lg" : "sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{fields.title}</DialogTitle>
        <DialogContent onScroll={() => handleScroll()}>
          <Container>{dialogContent()}</Container>
        </DialogContent>
        <DialogActions>
          <Container
            sx={{
              gridTemplateColumns: "auto auto",
              justifyContent: "end",
              gridGap: "1rem",
              padding: "12px 20px",
            }}
          >
            <Button
              id={"Cancel"}
              label="Cancel"
              variant="text"
              onClick={() => {
                handleClose();
                reset();
              }}
            />
            <Button
              id={fields.primaryBtn.label}
              loading={fields.primaryBtn.loading}
              onClick={handleSubmit(onSubmit)}
              color={
                fields.primaryBtn.color
                  ? fields.primaryBtn.color
                  : fields.type === "reject" ||
                    fields.type === "deleteRecurring"
                  ? "error"
                  : "primary"
              }
              label={fields.primaryBtn.label}
              disabled={fields.primaryBtn.disabled}
            />
          </Container>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default UpdateItemDialog;
