import { SubmitDialog, UpdateItemDialog } from "components";
import React, { useEffect, useState } from "react";
import { dialogFields, DialogueType } from "constants/itemUpdateActions";
import strings from "strings";
import {
  DropdownOptions,
  EndorsementPositionRequest,
  IPayment,
  IPaymentImage,
  ISubmitItemResult,
} from "interfaces";
import {
  changeItemAmount,
  deleteItemSubmitAttempts,
  deleteRecurringPaymentRequest,
  getPaymentImage,
  holdItem,
  markWireAsCompleted,
  overrideItemStatus,
  pauseRecurringRequestForPayment,
  rejectItem,
  returnItem,
  submitACHItems,
  submitItems,
  unpauseRecurringRequestForPayment,
  updateItemFileNumber,
} from "api";
import { amountIsValid, statusIsDifferentFromCurrent } from "tools/validators";
import { amountPattern } from "constants/regex";
import { useNavigate } from "react-router";
import toast from "components/toast";
import { paymentMethods } from "constants/paymentMethods";
import { fireworksConfetti } from "tools/confetti";
import { paymentStatus } from "constants/paymentStatus";
import { ISubmitCheckRequest } from "interfaces/IMultipleCheckDeposit";
import DepositItemDialog from "components/dialog/depositItemDialog";

interface IUpdateReceivableProps {
  selectedPaymentDetail: IPayment;
  handleSuccess: (a: number, b: string) => void;
  typeId: string | undefined;
  dropdownOptions: DropdownOptions[] | undefined;
  dialogueType: DialogueType | undefined;
  setShowUpdateItemDialog: (a: boolean) => void;
  showUpdateItemDialog: boolean;
  handleUpdateData: () => void;
}

const UpdateReceivableComponent = ({
  selectedPaymentDetail,
  handleSuccess,
  typeId,
  dropdownOptions,
  dialogueType,
  setShowUpdateItemDialog,
  showUpdateItemDialog,
  handleUpdateData,
}: IUpdateReceivableProps) => {
  const navigate = useNavigate();
  const [endorsed, setEndorsed] = useState<boolean>();
  const [backImage, setBackImage] = useState<IPaymentImage | undefined>();
  const [frontImage, setFrontImage] = useState<IPaymentImage | undefined>();
  const [endorsementRequest, setEndorsementRequest] = useState<
    EndorsementPositionRequest | undefined
  >();
  const [isRotated, setIsRotated] = useState<boolean>(false);

  const [availableAccounts, setAvailableAccounts] =
    useState<DropdownOptions[]>();
  const [loading, setLoading] = useState(false);
  const [endorsementRequired, setEndorsementRequired] =
    useState<boolean>(false);

  const isPassportCheckCapture =
    selectedPaymentDetail?.statusId == paymentStatus.PASSPORT_RC_COMPLETED;

  useEffect(() => {
    (async () => {
      console.debug("selected payment detail changed");
      if (!selectedPaymentDetail) {
        return;
      }
      try {
        const paymentImage = await getPaymentImage(+selectedPaymentDetail?.id);
        const back = paymentImage?.data?.images
          ?.filter((i) => i.imageIndex === "BACK")
          .pop();
        setBackImage(back);
        setEndorsed(back?.endorsed);
        const front = paymentImage?.data?.images
          ?.filter((i) => i.imageIndex === "FRONT")
          .pop();
        setFrontImage(front);
        setAvailableAccounts(dropdownOptions);
      } catch (err) {
        console.error("Could not load account options");
      }
    })();
  }, [selectedPaymentDetail]);

  const submitPrimaryBtnDisable = () => {
    if (!selectedPaymentDetail?.accountId || loading) {
      return true;
    }
    if (selectedPaymentDetail.paymentMethodId === paymentMethods.ACH_TRANSFER) {
      return false;
    }
    if ((endorsementRequired && !endorsed) || !backImage || !frontImage) {
      return true;
    }
  };

  const sumbitPrimaryBtnLoading = () => {
    if (loading) {
      return true;
    }
    if (
      selectedPaymentDetail?.paymentMethodId === paymentMethods.ACH_TRANSFER
    ) {
      return false;
    }
    if (!backImage || !frontImage) {
      return true;
    }
  };

  const sumbitPrimaryBtnLabel = () => {
    if (selectedPaymentDetail?.paymentMethodId == paymentMethods.ACH_TRANSFER) {
      return strings.DEPOSIT;
    }
    if (backImage?.endorsed || endorsed) {
      return strings.DEPOSIT_WITH_ENDORSEMENT;
    } else {
      return strings.DEPOSIT_WITHOUT_ENDORSEMENT;
    }
  };

  const fieldsByAction: dialogFields[] = [
    {
      type: "submit",
      title: strings.CONFIRM_DEPOSIT_ACTION,
      subtitle: "This item will be deposited: " + selectedPaymentDetail?.id,
      iconPath:
        selectedPaymentDetail?.paymentMethodId === paymentMethods.ACH_TRANSFER
          ? "icon_deposit"
          : undefined,
      dropdown: undefined,
      textField: {
        name: strings.NOTE.toLowerCase(),
        label: strings.OPTIONAL_NOTE,
        defaultValue: undefined,
        required: false,
      },
      endorsement:
        selectedPaymentDetail?.paymentMethodId === paymentMethods.ACH_TRANSFER
          ? undefined
          : {
              payment: selectedPaymentDetail,
              backImage: backImage,
              frontImage: frontImage,
              accountId: selectedPaymentDetail?.accountId,
              setEndorsement: (value: boolean) => setEndorsed(value),
              onSuccessCallback: (er: EndorsementPositionRequest) => {
                setEndorsementRequest(er);
              },
              isEndorseRequired: (val: boolean) => {
                setEndorsementRequired(val);
              },
              isRotated: (value: boolean) => setIsRotated(value),
            },
      endorsementStatus: (() => {
        if (endorsed) {
          return strings.CHECK_IS_ALREADY_ENDORSED;
        }
        if (selectedPaymentDetail?.endorsementRequired && !endorsed) {
          return strings.PLEASE_ADD_REQUIRED_ENDORSEMENT;
        }
        if (!selectedPaymentDetail?.endorsementRequired && !endorsed) {
          return strings.YOU_CAN_ADD_OPTIONAL_ENDORSEMENT;
        }
      })(),
      extraMessage: !selectedPaymentDetail?.accountId
        ? strings.ITEM_HAS_NO_ACCOUNTS
        : undefined,
      primaryBtn: {
        disabled: submitPrimaryBtnDisable(),
        label: sumbitPrimaryBtnLabel(),
        loading: sumbitPrimaryBtnLoading(),
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            formData.note = formData.note || "";
            if (
              selectedPaymentDetail?.paymentMethodId ===
              paymentMethods.ACH_TRANSFER
            ) {
              handleSubmitItems(
                formData.note,
                undefined,
                [],
                [selectedPaymentDetail.id]
              );
            } else {
              handleSubmitItems(
                formData.note,
                endorsementRequest,
                [selectedPaymentDetail.id],
                []
              );
            }
          }
        },
      },
    },
    {
      type: "return",
      title: strings.RETURN_TITLE,
      subtitle: strings.RETURN_SUBTITLE,
      iconPath: undefined,
      dropdown: undefined,
      textField: {
        name: strings.COMMENT.toLowerCase(),
        label: strings.OPTIONAL_NOTE,
        defaultValue: undefined,
        required: false,
      },
      primaryBtn: {
        label: strings.RETURN,
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            formData.note = formData.note || "";
            returnItem(selectedPaymentDetail?.id, formData.note)
              .then((response) =>
                handleSuccess(
                  response.data.itemId,
                  strings.ITEM_RETURNED_SUCCESSFULLY
                )
              )
              .finally(() => navigate(`/payments/${typeId}`));
          }
        },
      },
    },
    {
      type: "hold",
      title: strings.CONFIRM_HOLD_ACTION,
      subtitle: strings.ITEM_PLACED_ON_HOLD,
      iconPath: "icon_hold",
      dropdown: undefined,
      textField: {
        name: strings.NOTE.toLowerCase(),
        label: strings.OPTIONAL_NOTE,
        defaultValue: undefined,
        required: false,
      },
      extraMessage: undefined,
      primaryBtn: {
        label: strings.HOLD,
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            formData.note = formData.note || "";
            holdItem(selectedPaymentDetail?.id, formData.note)
              .then((response) =>
                handleSuccess(
                  response.data.itemId,
                  strings.ITEM_HOLD_SUCCESSFULLY
                )
              )
              .finally(() => navigate(`/payments/${typeId}`));
          }
        },
      },
    },
    {
      type: "reject",
      title: strings.CONFIRM_REJECT_ACTION,
      subtitle: strings.ITEM_WILL_BE_REJECTED,
      iconPath: "icon_reject",
      dropdown: undefined,
      textField: {
        name: strings.NOTE.toLowerCase(),
        label: strings.OPTIONAL_NOTE,
        defaultValue: undefined,
        required: false,
      },
      extraMessage: undefined,
      primaryBtn: {
        label: strings.REJECT,
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            formData.note = formData.note || "";
            rejectItem(selectedPaymentDetail?.id, formData.note)
              .then((response) =>
                handleSuccess(
                  response.data.itemId,
                  strings.ITEM_REJECT_SUCCESSFULLY
                )
              )
              .finally(() => navigate(`/payments/${typeId}`));
          }
        },
      },
    },
    {
      type: "override",
      title: strings.OVERRIDE_ITEM_STATUS,
      subtitle:
        strings.SELECT_NEW_STATUS +
        strings.MUST_BE_DIFFERENT_FROM_CURRENT +
        ` (${selectedPaymentDetail?.status})`,
      iconPath: undefined,
      dropdown: {
        options: dropdownOptions,
        label: strings.SELECT_NEW_STATUS,
        defaultValue: selectedPaymentDetail?.statusId.toString(), //Forcing string to avoid 0 (number) interpreted as undefined
        required: true,
        name: "newStatusId",
        validate: (textFieldValue: string) =>
          statusIsDifferentFromCurrent(
            textFieldValue,
            selectedPaymentDetail?.statusId.toString() || ""
          ),
      },
      textField: {
        name: strings.COMMENT.toLowerCase(),
        label: strings.ADDITIONAL_COMMENT,
        defaultValue: undefined,
        required: true,
      },
      extraMessage: strings.OVERRIDE_EXTRA_MESSAGE,
      primaryBtn: {
        label: strings.OVERRIDE,
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            overrideItemStatus(
              selectedPaymentDetail?.id,
              formData?.newStatusId,
              formData?.comment
            )
              .then((response) =>
                handleSuccess(
                  response.data.itemId,
                  strings.ITEM_OVERRIDEN_SUCCESSFULLY
                )
              )
              .finally(() => navigate(`/payments/${typeId}`));
          }
        },
      },
    },
    {
      type: "changeAmount",
      title: strings.CONFIRM_CHANGE_AMOUNT_ACTION,
      subtitle: strings.ENTER_NEW_AMOUNT,
      iconPath: undefined,
      dropdown: undefined,
      textField: {
        name: "amountInDollars",
        label: strings.NEW_AMOUNT,
        defaultValue: selectedPaymentDetail?.amount,
        required: true,
        currency: true,
        validate: (textFieldValue: string) => amountIsValid(textFieldValue),
        maxLength: 13,
      },
      extraMessage: undefined,
      primaryBtn: {
        label: strings.CHANGE_AMOUNT,
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            const match = formData?.amountInDollars?.match(amountPattern);
            if (!match) {
              return;
            }
            const amountAsFloat = parseFloat(match[1]);
            changeItemAmount(
              selectedPaymentDetail?.id,
              Number((amountAsFloat * 100).toFixed(0))
            )
              .then((response) =>
                handleSuccess(
                  response.data.itemId,
                  strings.ITEM_AMOUNT_CHANGED_SUCCESSFULLY
                )
              )
              .finally(() => navigate(`/payments/${typeId}`));
          }
        },
      },
    },
    {
      type: "changeFileNumber",
      title: strings.CONFIRM_CHANGE_FILE_NUMBER_ACTION,
      subtitle: strings.ENTER_NEW_FILE_NUMBER,
      iconPath: undefined,
      dropdown: undefined,
      textField: {
        name: "fileNumber",
        label: strings.NEW_FILE_NUMBER,
        defaultValue: selectedPaymentDetail?.fileNumber,
        maxLength: 32,
      },
      extraMessage: strings.EXTRA_COMMENT_FILE_NUMBER,
      primaryBtn: {
        label: strings.CHANGE_FILE_NUMBER,
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            updateItemFileNumber(
              selectedPaymentDetail?.id,
              formData?.fileNumber
            )
              .then((response) => {
                handleSuccess(
                  response.data.itemId,
                  strings.ITEM_FILE_NUMBER_CHANGED_SUCCESSFULLY
                );
                handleUpdateData();
              })
              .finally(() => {
                setShowUpdateItemDialog(false);
              });
          }
        },
      },
    },
    {
      type: "approve",
      title: strings.CONFIRM_DEPOSIT_ACTION,
      subtitle: "",
      iconPath: undefined,
      dropdown: {
        disabled: !availableAccounts || availableAccounts.length === 0,
        options: dropdownOptions,
        label: strings.DESTINATION_ACCOUNT,
        defaultValue: undefined,
        name: "accountId",
        required: true,
      },
      dropdownPaymentMethods:
        selectedPaymentDetail?.paymentMethodId ===
          paymentMethods.ELECTRONIC_PAYMENT || isPassportCheckCapture,
      textField:
        selectedPaymentDetail?.paymentMethodId ===
          paymentMethods.CHECK_CAPTURE && !isPassportCheckCapture
          ? {
              name: strings.NOTE.toLowerCase(),
              label: strings.OPTIONAL_NOTE,
              defaultValue: undefined,
              required: false,
            }
          : undefined,
      endorsement:
        selectedPaymentDetail?.paymentMethodId ===
          paymentMethods.ACH_TRANSFER ||
        selectedPaymentDetail?.paymentMethodId ===
          paymentMethods.ELECTRONIC_PAYMENT
          ? undefined
          : {
              payment: selectedPaymentDetail,
              backImage: backImage,
              frontImage: frontImage,
              setEndorsement: (value: boolean) => setEndorsed(value),
              onSuccessCallback: (er: EndorsementPositionRequest) => {
                setEndorsementRequest(er);
              },
            },
      endorsementStatus: (() => {
        if (endorsed) {
          return strings.CHECK_IS_ALREADY_ENDORSED;
        }
        if (selectedPaymentDetail?.endorsementRequired && !endorsed) {
          return strings.PLEASE_ADD_REQUIRED_ENDORSEMENT;
        }
        if (!selectedPaymentDetail?.endorsementRequired && !endorsed) {
          return strings.YOU_CAN_ADD_OPTIONAL_ENDORSEMENT;
        }
      })(),
      extraMessage: undefined,
      primaryBtn: {
        disabled:
          !availableAccounts ||
          availableAccounts.length === 0 ||
          !backImage ||
          !frontImage,
        loading: !backImage || !frontImage,
        label:
          backImage?.endorsed || endorsed
            ? strings.DEPOSIT_WITH_ENDORSEMENT
            : strings.DEPOSIT_WITHOUT_ENDORSEMENT,
        onClick: (formData: any) => {},
      },
      routeTypeId: typeId,
      endorsementRequest: endorsementRequest,
    },

    {
      type: "pause",
      title: "Pause recurring payment request",
      subtitle:
        "This action will cause that this recurring payment will not be sent until you resume it.",
      iconPath: "icon_dialog_warning",
      primaryBtn: {
        color: "warning",
        loading: loading,
        label: "Confirm Pause",
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            setLoading(true);
            pauseRecurringRequestForPayment(
              selectedPaymentDetail.recurringPaymentId
            )
              .then((resp) => {
                toast({
                  type: "success",
                  title: resp.message,
                });
                navigate(`/payments/${typeId}`);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        },
      },
    },
    {
      type: "unpause",
      title: "Resume recurring payment request",
      subtitle:
        "This action will cause that this recurring payment will be sent again.",
      iconPath: "icon_dialog_success",
      primaryBtn: {
        color: "success",
        loading: loading,
        label: "Confirm Resume",
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            setLoading(true);
            unpauseRecurringRequestForPayment(
              selectedPaymentDetail.recurringPaymentId
            )
              .then((resp) => {
                toast({
                  type: "success",
                  title: resp.message,
                });
                navigate(`/payments/${typeId}`);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        },
      },
    },
    {
      type: "deleteRecurring",
      title: strings.CONFIRM_DELETE_ACTION,
      subtitle: strings.RECURRING_REQUEST_WILL_BE_DELETED,
      iconPath: "icon_dialog_delete",
      dropdown: undefined,
      textField: undefined,
      extraMessage: undefined,
      primaryBtn: {
        loading: loading,
        label: strings.DELETE,
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            setLoading(true);
            deleteRecurringPaymentRequest(
              selectedPaymentDetail.recurringPaymentId!
            )
              .then((resp: any) => {
                toast({
                  type: "success",
                  title: resp.data.message,
                });
                navigate(`/payments/${typeId}`);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        },
      },
    },
    {
      type: "clearSubmitAttempt",
      title: strings.CLEAR_SUBMIT_ATTEMPT_TITLE,
      subtitle: "",
      extraMessage: strings.CLEAR_SUBMIT_ATTEMPT_SUBTITLE,
      iconPath: undefined,
      dropdown: undefined,
      primaryBtn: {
        label: strings.CLEAR_SUBMIT_ATTEMPT,
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            setLoading(true);
            deleteItemSubmitAttempts(selectedPaymentDetail.id)
              .then((resp) => {
                toast({
                  type: "success",
                  title: resp.data.message,
                });
                navigate(`/payments/${typeId}`);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        },
      },
    },
    {
      type: "markAsCompleted",
      title: strings.MARK_AS_COMPLETED_TITLE,
      subtitle: "",
      extraMessage: strings.MARK_AS_COMPLETED_SUBTITLE,
      iconPath: undefined,
      dropdown: undefined,
      primaryBtn: {
        label: strings.MARK_AS_COMPLETED,
        onClick: (formData: any) => {
          if (selectedPaymentDetail) {
            setLoading(true);
            markWireAsCompleted(selectedPaymentDetail.id)
              .then((resp) => {
                toast({
                  type: "success",
                  title:
                    "Item with id: " +
                    selectedPaymentDetail.id +
                    " marked as completed",
                });
                navigate(`/payments/${typeId}`);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        },
      },
    },
  ];

  const selectedAction: dialogFields =
    fieldsByAction.find((field) => field.type === dialogueType) ||
    fieldsByAction[0];

  const [errorMessage, setErrorMessage] = useState({
    title: "",
    subTitle: "",
  });
  const [showErrModal, setShowErrModal] = useState(false);

  const handleSubmitResult = (result: ISubmitItemResult) => {
    setLoading(false);
    const type = result.success ? "success" : "error";
    const message = result.message || result.error || result.reason || "";
    if (type === "error") {
      setErrorMessage({
        title: `${strings.ITEM_ID}: ${result.id}`,
        subTitle: message,
      });
      setShowErrModal(true);
      return;
    }
    fireworksConfetti();
    navigate(`/payments/${typeId}`);
    toast({
      type: type,
      title: message,
      subTitle: `${strings.ITEM_ID}: ${result.id}`,
    });
    navigate(`/payments/${typeId}`);
    setShowUpdateItemDialog(false);
  };

  const handleSubmitItems = async (
    note: string,
    chequeImageEndorsementRequest?: EndorsementPositionRequest,
    itemsToSubmit: number[] = [],
    achItemsToSubmit: number[] = []
  ) => {
    setLoading(true);
    if (itemsToSubmit.length > 0) {
      const request: ISubmitCheckRequest = {
        items: itemsToSubmit,
        chequeImageEndorsementListRequest: [
          {
            imageId: backImage?.id,
            accountId: selectedPaymentDetail?.accountId,
            rotate: isRotated,
            coordinatesRequest: chequeImageEndorsementRequest,
          },
        ],
        note: note,
      };
      const submitItemResult = await submitItems(request);
      submitItemResult?.data?.forEach((result) => {
        handleSubmitResult(result);
      });
    }
    if (achItemsToSubmit.length > 0) {
      const submitAchResult = await submitACHItems(achItemsToSubmit);
      submitAchResult?.data?.forEach((result) => {
        handleSubmitResult(result);
      });
    }
  };

  return (
    <>
      <UpdateItemDialog
        open={showUpdateItemDialog && dialogueType !== "approve"}
        handleClose={() => {
          setShowUpdateItemDialog(false);
        }}
        fields={selectedAction}
        selectedPayment={selectedPaymentDetail}
      />
      <DepositItemDialog
        open={showUpdateItemDialog && dialogueType === "approve"}
        handleClose={() => {
          handleUpdateData();
          setShowUpdateItemDialog(false);
        }}
        fields={selectedAction}
        selectedPayment={selectedPaymentDetail}
      />
      <SubmitDialog
        type="warning"
        title={strings.SUBMIT_ERROR}
        open={showErrModal}
        body1={errorMessage.title}
        body2={errorMessage.subTitle}
        handleClose={() => setShowErrModal(false)}
        primaryBtnProps={{
          label: strings.OK,
          onClick: () => setShowErrModal(false),
        }}
        showCancel={false}
      />
    </>
  );
};

export { UpdateReceivableComponent };
