import { Container, Icon, TypoGraph } from "components";

import styles from "./steps.module.scss";
import { useEffect, useState } from "react";

const StepsComponent = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const howWorksItems: any[] = [
    {
      icon: "icon_people_search_primary",
      tittle: "Find your company",
      line1: "Enter the company code provided.",
      line2: "",
      mobileText: "Enter the company code provided.",
    },
    {
      icon: "icon_form_primary",
      tittle: "Provide transaction details",
      line1: "Provide required transaction",
      line2: "details.",
      mobileText: "Provide required transaction details.",
    },
    {
      icon: "icon_bank_primary",
      tittle: "Send Payment",
      line1: "Securely send your payment",
      line2: "to your company or agency.",
      mobileText: "Securely send your payment to your company or agency.",
    },
  ];

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <Container className={styles.stepsContainer}>
      {windowWidth >= 665 && (
        <Container>
          <TypoGraph
            content={"How it works?"}
            variant="h2"
            align={"center"}
            color={"#32334C"}
            sx={{ "margin-top": "16px" }}
          />
          <TypoGraph
            content={
              "Bank Shot helps facilitate secure payments between home buyers and brokers."
            }
            variant="body1"
            align={"center"}
            color={"#32334C"}
            sx={{ "margin-top": "5px", "margin-bottom": "20px" }}
          />
          <Container className={styles.howWorksIcons}>
            {howWorksItems.map((item) => {
              return (
                <Container>
                  <Icon name={item.icon} size={30} />
                  <TypoGraph
                    content={item.tittle}
                    align={"center"}
                    color={"#7851f8"}
                    sx={{ "font-weight": "bolder", "margin-top": "16px" }}
                  />
                  <TypoGraph
                    content={item.line1}
                    align={"center"}
                    variant={"body2"}
                    color={"#666666"}
                  />
                  <TypoGraph
                    content={item.line2}
                    align={"center"}
                    variant={"body2"}
                    color={"#666666"}
                  />
                </Container>
              );
            })}
          </Container>
        </Container>
      )}
      {windowWidth < 665 && (
        <Container sx={{ "margin-top": "-50px" }}>
          <Container className={styles.howWorksIconsMobile}>
            {howWorksItems.map((item) => {
              return (
                <Container className={styles.stepsContainer}>
                  <Container className={styles.iconContainer}>
                    <Icon name={item.icon} size={30} />
                  </Container>

                  <Container>
                    <TypoGraph
                      content={item.tittle}
                      color={"#7851f8"}
                      sx={{ "font-weight": "bolder", "margin-top": "16px" }}
                    />
                    <TypoGraph
                      content={item.mobileText}
                      variant={"body2"}
                      color={"#666666"}
                      sx={{ width: "300px" }}
                    />
                  </Container>
                </Container>
              );
            })}
          </Container>
        </Container>
      )}
    </Container>
  );
};

export default StepsComponent;
