import { useFormContext } from "react-hook-form";
import {
  BillingAddressForm,
  Container,
  CustomDivider,
  Icon,
  TypoGraph,
} from "components";
import { CheckboxFormField, RadioFormField } from "components/hookForm";
import {
  AchPayment,
  CheckCapturePayment,
  DigitalCheckPayment,
  CreditCardPayment,
  WirePayment,
} from "./paymentMethods";
import colors from "theme/colors";
import styles from "./authorizePayment.module.scss";
import { locatedInData, propertyAddress } from "tools/format";
import Decimal from "decimal.js";
import {
  DropdownOptions,
  IWebReceivablePaymentData,
  IWhitelabelConfigurationResponse,
} from "interfaces";
import { IRadioGroupData } from "components/controllers/radio";
import React, { useEffect, useState } from "react";
import strings from "strings";
import Typograph from "components/typograph";
import { paymentMethods } from "constants/paymentMethods";
import { Box, Tooltip } from "@mui/material";
import { prFeeOptions } from "constants/prFeeOptions";

interface IAuthorizePaymentProps {
  paymentData: IWebReceivablePaymentData;
  statesOptions?: DropdownOptions[];
  whitelabelConfiguration?: IWhitelabelConfigurationResponse;
  companyChargesCostToBuyer: boolean;
}

export const electronicPaymentAmountLimit = 1000000;
export const electronicPaymentAmountLimitInDollars = 10000;

const AuthorizePayment: React.FC<IAuthorizePaymentProps> = (props) => {
  const {
    paymentData,
    statesOptions,
    whitelabelConfiguration,
    companyChargesCostToBuyer,
  } = props;
  const { watch, getValues, setValue } = useFormContext();
  const paymentMethod = watch("paymentMethod");
  const creditCardFee: number = watch("creditCardFee");
  const formValues = getValues();
  const [sectionTitle, setSectionTitle] = useState("");
  const isDue = paymentData.paymentRequested?.prDue;
  const paymentMethodsWithout50kAmountLimit = [0, 5];
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<
    number | undefined
  >(getDefaultPaymentMethod());
  function buildPaymentMethods(): IRadioGroupData[] {
    return paymentData.availablePaymentMethods.map((paymentMethod) => {
      return {
        value: paymentMethod.id,
        label: paymentMethod.name,
        disabled: isDisabledMethod(paymentMethod.id),
        tooltipText: strings.WEB_RECEIVABLES.AMOUNT_IS_OVER_50K,
      };
    });
  }

  function isDisabledMethod(methodId: number) {
    const amountInCent = new Decimal(formValues.amount).times(100).toNumber();
    return (
      amountInCent > electronicPaymentAmountLimit &&
      !paymentMethodsWithout50kAmountLimit.includes(methodId)
    );
  }

  const getPaymentMethodName = (paymentMethodId: number) => {
    return paymentData.availablePaymentMethods.find(
      (paymentMethod) => paymentMethod.id == paymentMethodId
    )?.name;
  };

  const EmptyPaymentTypeComponent = () => (
    <Container className={styles.securityContentContainer}>
      <Icon
        name="icon_info"
        size={25}
        color={whitelabelConfiguration?.configJson.backgroundColor}
      />
      <TypoGraph
        color={
          whitelabelConfiguration?.configJson.backgroundColor
            ? whitelabelConfiguration.configJson.backgroundColor
            : colors.primary2
        }
        content="First select your payment method before proceeding"
      />
    </Container>
  );

  const NonCheckPaymentTypeComponent = () => (
    <Container
      className={styles.securityContentContainer}
      sx={{ marginBottom: "2rem" }}
    >
      <Icon
        name="icon_info"
        size={25}
        color={whitelabelConfiguration?.configJson.backgroundColor}
      />
      <TypoGraph
        color={
          whitelabelConfiguration?.configJson.backgroundColor
            ? whitelabelConfiguration.configJson.backgroundColor
            : colors.primary2
        }
        content="Money transmission services are provided by Priority Technology Holdings, Inc., directly or through its subsidiary Finxera, Inc. (NMLS #1168701), or its authorized affiliates and contractors."
      />
    </Container>
  );

  const CheckPaymentTypeComponent = () => (
    <Container
      className={styles.securityContentContainer}
      sx={{ marginBottom: "2rem" }}
    >
      <Icon
        name="icon_info"
        size={25}
        color={whitelabelConfiguration?.configJson.backgroundColor}
      />
      <TypoGraph
        color={
          whitelabelConfiguration?.configJson.backgroundColor
            ? whitelabelConfiguration.configJson.backgroundColor
            : colors.primary2
        }
      >
        <>
          1. Capture the photos of the check using your device in landscape
          orientation (horizontal).
          <br />
          2. Make sure the check is laying flat on a contrasting surface.
          <br />
          3. Lighting - natural lighting is best, but if a flash is necessary,
          ensure that the surface is non-reflective.
          <br />
          4. All four corners of the check must be visible. Remove the check
          from any checkbooks or attached receipts.
          <br />
          5. The MICR (Magnetic Ink Character Recognition Line) must be fully
          visible and unobscured. If the signature or anything else is
          intruding, the processor will be unable to read the account, routing,
          and check numbers.
        </>
      </TypoGraph>
    </Container>
  );

  useEffect(() => {
    if (paymentData.availablePaymentMethods.length == 1) {
      setValue("paymentMethod", paymentData.availablePaymentMethods[0].id);
    }
  }, [watch("paymentMethod")]);

  function getDefaultPaymentMethod() {
    if (paymentData.availablePaymentMethods.length == 1) {
      return paymentData.availablePaymentMethods[0].id;
    }
    return undefined;
  }

  const PaymentTypeComponent = () => {
    switch (+paymentMethod) {
      case 0:
        setSectionTitle("Authorize Payment");
        return (
          <CheckCapturePayment
            whitelabelConfiguration={whitelabelConfiguration?.configJson}
            companyChargesCostToBuyer={companyChargesCostToBuyer}
          />
        );
      case 3:
        setSectionTitle("Electronic Payment - Details");
        return <AchPayment />;
      case 2:
        return <DigitalCheckPayment />;
      case 5:
        setSectionTitle("Credit Card - Payment Details");
        return <CreditCardPayment />;
      case 4:
        setSectionTitle("Wire Transfer");
        return (
          <WirePayment
            base64={paymentData.paymentRequested?.wiringInstructionsPDFBase64}
            whitelabelConfiguration={whitelabelConfiguration?.configJson}
            formattedAmount={paymentData.paymentRequested?.amount}
          />
        );
      default:
        return <EmptyPaymentTypeComponent />;
    }
  };

  const FooterText = () => {
    return (
      <Container className={styles.contentContainer}>
        <TypoGraph className={styles.typoContainer}>
          <Icon
            imageId={"icon_webLogo"}
            type={"images"}
            url={`data:image/png;base64,${whitelabelConfiguration?.logoBase64}`}
            width={147}
            alt={"vector_images"}
          />
          <TypoGraph component="span" content="You are about to send " />
          <TypoGraph
            component="span"
            color={whitelabelConfiguration?.configJson?.backgroundColor}
            content={paymentData.companyName}
          />
          <TypoGraph component="span" content=" a secure " />
          <TypoGraph
            component="span"
            color={whitelabelConfiguration?.configJson?.backgroundColor}
            content={`${
              paymentData.paymentTypeId === 2
                ? "Commission Check"
                : paymentData.paymentType
            }`}
          />
          <TypoGraph component="span" content=" payment of " />
          <TypoGraph
            component="span"
            color={whitelabelConfiguration?.configJson?.backgroundColor}
            content={`${addFeesToAmount()}`}
          />
          {paymentData.paymentTypeId !== 5 && (
            <>
              <TypoGraph component="span" content=" for " />
              <TypoGraph
                component="span"
                color={whitelabelConfiguration?.configJson?.backgroundColor}
                content={`${propertyAddress(formValues)}`}
              />
              <TypoGraph component="span" content=" located in " />
              <TypoGraph
                component="span"
                color={whitelabelConfiguration?.configJson?.backgroundColor}
                content={locatedInData(formValues)}
              />
            </>
          )}
          <TypoGraph component="span" content=" using payment method " />
          <TypoGraph
            component="span"
            color={whitelabelConfiguration?.configJson?.backgroundColor}
            content={getPaymentMethodName(formValues.paymentMethod)}
          />
        </TypoGraph>
      </Container>
    );
  };

  const linkToTermsAndConditions = (
    <TypoGraph>
      <TypoGraph component="span" content="I agree to " />
      <TypoGraph
        component="span"
        content="Bank Shot's Terms & Conditions"
        className={styles.disclaimerLink}
        onClick={() =>
          window.open("https://www.getbankshot.com/terms-of-use", "_blank")
        }
        link
      />
    </TypoGraph>
  );

  const WireDisclaimer = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
    });
    return (
      <TypoGraph>
        <TypoGraph
          component="span"
          content="By checking this box, I confirm that the wire payment was successfully completed on "
        />
        <TypoGraph component="span" content={formattedDate + `.`} />
      </TypoGraph>
    );
  };

  const feeCheckBoxText = (feeAmount: string) => (
    <TypoGraph
      component="span"
      content={`By checking this box, I agree to pay an additional transaction processing fee of ${feeAmount} on top of my payment for Bank Shot’s services. I authorize this fee amount to be added to my total payment transaction.`}
    />
  );

  const CustomCheckbox = () => {
    const customCheckboxes = whitelabelConfiguration?.configJson.customCheckbox;

    const filteredCheckboxes = customCheckboxes?.filter(
      (checkbox) =>
        !checkbox.excludedPaymentTypes?.includes(paymentData.paymentTypeId)
    );

    return (
      <>
        {filteredCheckboxes?.map((checkbox) => (
          <CheckboxFormField
            key={checkbox.label}
            name={checkbox.label}
            componentLabel={
              <TypoGraph>
                <TypoGraph component="span" content={`${checkbox.label} `} />
                {checkbox.linkeableLabel && (
                  <TypoGraph
                    component="span"
                    content={checkbox.linkeableLabel.label}
                    className={styles.disclaimerLink}
                    onClick={() =>
                      window.open(checkbox.linkeableLabel.link, "_blank")
                    }
                    link
                  />
                )}
              </TypoGraph>
            }
            required
          />
        )) || null}
      </>
    );
  };

  const addFeesToAmount = (): string => {
    const amountAsNumber = parseFloat(formValues.amount);
    let feeAsNumber = 0;
    switch (+paymentMethod) {
      case paymentMethods.ELECTRONIC_PAYMENT:
        feeAsNumber = parseFloat(paymentData.achFee!.substring(1));
        break;
      case paymentMethods.CREDIT_CARD:
        if (creditCardFee) {
          feeAsNumber = creditCardFee;
        }
        break;
      default:
        feeAsNumber = 0;
        break;
    }
    const dueFee = isDue ? parseFloat(dueFeeValue().replace("$", "")) : 0;

    if (!isNaN(amountAsNumber)) {
      const result = amountAsNumber + feeAsNumber + dueFee;
      return `$${result.toFixed(2)}`;
    } else {
      return "Invalid amount";
    }
  };

  const dueFeeValue = (): string => {
    const dueFee = paymentData.paymentRequested?.prFeeAmount!;
    const amount = parseInt(
      paymentData.paymentRequested?.amount.replace(".", "")!
    );
    const feeAmount = dueFee - amount;
    const feeAmountFixed = (feeAmount / 100).toFixed(2);
    return `$${feeAmountFixed}`;
  };

  const dueFeeToolTip = (): string => {
    const type = paymentData.paymentRequested?.prFeeType;
    const value: any = paymentData.paymentRequested?.prFeeValue;
    const dueDate = paymentData.paymentRequested?.prDueDate;
    const valueText =
      type == prFeeOptions.PERCENTAGE ? `${value}%` : dueFeeValue();

    return `The due date of this payment was ${dueDate}, for this reason, a fee of ${valueText} was added.`;
  };

  const ACHFeeSumAmountComponent = () => (
    <Container>
      <Container className={styles.disclaimerContainer}>
        <Container className={styles.disclaimerContainerFee}>
          <Typograph
            component="span"
            content={`${paymentData?.paymentType}`}
          ></Typograph>
          <TypoGraph
            component="span"
            content={`$${formValues.amount}`}
            className={styles.disclaimerContainerAmount}
          />
        </Container>
        {isDue && <DueFeeTable />}
        <Container className={styles.disclaimerContainerFee}>
          <Typograph component="span" content={`Transaction Fee`}></Typograph>
          <TypoGraph
            component="span"
            content={paymentData.achFee}
            className={styles.disclaimerContainerAmount}
          />
        </Container>
      </Container>
      <CustomDivider marginTopCustom={-1} />
      <Container className={styles.disclaimerContainerTotalAmount}>
        <Typograph component="span" content={`Total`}></Typograph>
        {paymentData.achFee && (
          <TypoGraph
            component="span"
            content={`${addFeesToAmount()}`}
            className={styles.disclaimerContainerAmount}
          />
        )}
      </Container>
    </Container>
  );

  const CreditCardFeeSumAmountComponent = () => (
    <Container>
      <Container className={styles.disclaimerContainer}>
        <Container className={styles.disclaimerContainerFee}>
          <Typograph
            component="span"
            content={`${paymentData?.paymentType}`}
          ></Typograph>
          <TypoGraph
            component="span"
            content={`$${formValues.amount}`}
            className={styles.disclaimerContainerAmount}
          />
        </Container>
        {isDue && <DueFeeTable />}
        {creditCardFee && (
          <Container className={styles.disclaimerContainerFee}>
            <Typograph component="span" content={`Transaction Fee`}></Typograph>
            <TypoGraph
              component="span"
              content={`$${creditCardFee.toFixed(2)}`}
              className={styles.disclaimerContainerAmount}
            />
          </Container>
        )}
      </Container>
      <CustomDivider marginTopCustom={-1} />
      <Container className={styles.disclaimerContainerTotalAmount}>
        <Typograph component="span" content={`Total`}></Typograph>
        {creditCardFee && (
          <TypoGraph
            component="span"
            content={`${addFeesToAmount()}`}
            className={styles.disclaimerContainerAmount}
          />
        )}
      </Container>
    </Container>
  );

  const DueFeeSumAmountComponent = () => (
    <Container>
      <Container className={styles.disclaimerContainer}>
        <Container className={styles.disclaimerContainerFee}>
          <Typograph
            component="span"
            content={`${paymentData?.paymentType}`}
          ></Typograph>
          <TypoGraph
            component="span"
            content={`$${formValues.amount}`}
            className={styles.disclaimerContainerAmount}
          />
        </Container>
      </Container>
      <DueFeeTable />
      <CustomDivider marginTopCustom={-1} />
      <Container className={styles.disclaimerContainerTotalAmount}>
        <Typograph component="span" content={`Total`}></Typograph>
        {paymentData.achFee && (
          <TypoGraph
            component="span"
            content={`${addFeesToAmount()}`}
            className={styles.disclaimerContainerAmount}
          />
        )}
      </Container>
    </Container>
  );

  const DueFeeTable = () => (
    <Container className={styles.disclaimerContainerFee}>
      <Container sx={{ display: "flex" }}>
        <Typograph component="span" content={`Late Fee`}></Typograph>
        <Tooltip title={dueFeeToolTip()} arrow>
          <Box mt={0.3} ml={0.2}>
            <Icon name="icon_info" size={20}></Icon>
          </Box>
        </Tooltip>
      </Container>

      <TypoGraph
        component="span"
        content={dueFeeValue()}
        className={styles.disclaimerContainerAmount}
      />
    </Container>
  );

  return (
    <Container className={styles.mainContainer}>
      {!paymentData.availablePaymentMethods ||
      paymentData.availablePaymentMethods.length === 0 ? (
        <Container className={styles.textContainer}>
          <TypoGraph
            align="center"
            variant="h1"
            content="This company has no available payment methods to send the payment."
          />
        </Container>
      ) : (
        <>
          <CustomDivider title="Payment Method" />
          <Container className={styles.radioButtonContainer}>
            <RadioFormField
              name="paymentMethod"
              radioGroupData={buildPaymentMethods()}
              defaultValue={defaultPaymentMethod}
              required
            />
          </Container>
          <CustomDivider title={sectionTitle} />
          <Container
            className={
              +paymentMethod === paymentMethods.CHECK_CAPTURE
                ? styles.paymentContainer
                : ""
            }
          >
            <PaymentTypeComponent />
          </Container>
          {(+paymentMethod === paymentMethods.ELECTRONIC_PAYMENT ||
            +paymentMethod === paymentMethods.CREDIT_CARD) && (
            <Container>
              <BillingAddressForm statesOptions={statesOptions} />
              <Container>
                <CustomDivider title="Disclaimer" />
                <CheckboxFormField
                  name="disclaimer"
                  required
                  componentLabel={linkToTermsAndConditions}
                />
                <CustomCheckbox />
                {+paymentMethod === paymentMethods.ELECTRONIC_PAYMENT && (
                  <CheckboxFormField
                    name="disclaimer2"
                    required
                    componentLabel={feeCheckBoxText(paymentData.achFee || "")}
                  />
                )}
                {+paymentMethod === paymentMethods.CREDIT_CARD &&
                  creditCardFee && (
                    <CheckboxFormField
                      name="disclaimer2"
                      required
                      componentLabel={feeCheckBoxText(
                        `$${creditCardFee.toFixed(2)}`
                      )}
                    />
                  )}
              </Container>
              {+paymentMethod === paymentMethods.ELECTRONIC_PAYMENT && (
                <ACHFeeSumAmountComponent />
              )}
              {+paymentMethod === paymentMethods.CREDIT_CARD && (
                <CreditCardFeeSumAmountComponent />
              )}
              <NonCheckPaymentTypeComponent />
            </Container>
          )}
          {+paymentMethod === paymentMethods.CHECK_CAPTURE && (
            <Container>
              <CheckPaymentTypeComponent />
              <FooterText />
              <Container>
                <CustomDivider title="Disclaimer" />
                <CheckboxFormField
                  name="disclaimer"
                  required
                  componentLabel={linkToTermsAndConditions}
                />
                <CustomCheckbox />
                {isDue && <DueFeeSumAmountComponent />}
                {companyChargesCostToBuyer && (
                  <CheckboxFormField
                    name="disclaimer2"
                    required
                    componentLabel={
                      <TypoGraph
                        component="span"
                        content={
                          "By checking this box, I agree that an ACH will be pulled from the account sending the check."
                        }
                      />
                    }
                  />
                )}
              </Container>
            </Container>
          )}
          {+paymentMethod === paymentMethods.WIRE_TRANSFER && (
            <Container>
              <Container>
                <CustomDivider title="Disclaimer" />
                <CheckboxFormField
                  name="disclaimer"
                  required
                  componentLabel={<WireDisclaimer />}
                />
                <CustomCheckbox />
              </Container>
            </Container>
          )}
        </>
      )}
    </Container>
  );
};

export default AuthorizePayment;
