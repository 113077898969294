export const actionText: { [action: string]: string } = {
  hold: "Hold",
  reject: "Reject",
  transfer: "Transfer",
  deposit: "Deposit",
  submittobp: "Submit to BP",
  note: "Note",
  bpresult: "BP Result",
  limithold: "Limit Hold",
  releasehold: "Release Hold",
  submitattempt: "Submit Attempt",
  overrideitemstatus: "Override item status",
  ACH_QUEUE_SUBMIT: "ACH Queue Submit",
  ACH_SUBMIT_ATTEMPT: "ACH Submit Attempt",
  ACH_SUBMIT_RESULT: "ACH Submit Result",
  ACH_SUBMIT_RETRY: "ACH Submit Retry",
  ACH_STATUS_UPDATE: "ACH Status Update",
  DC_PRINT_ATTEMPT: "DC Print Attempt",
  DC_PRINT_RESULT: "DC Print Result",
  ENDORSED: "Endorsed",
  ADJUSTED: "Adjusted",
  SUBMITTED_TO_PASSPORT: "Submitted To Passport",
  FUNDS_VALIDATED: "Funds Validated",
  FUNDS_REJECTED: "Funds Rejected",
  PAUSE_RECURRING_PAYMENT_REQUEST: "Paused recurring payment request",
  RESUME_RECURRING_PAYMENT_REQUEST: "Resumed recurring payment request",
  MARKED_AS_COMPLETED: "Marked as Completed",
};
