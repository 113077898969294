import { IButtonProps } from "components/button";
import { IDropdownFieldProps } from "components/hookForm/dropdownFormField";
import { IInputFieldProps } from "components/hookForm/inputFormField";
import { EndorsementPositionRequest } from "interfaces";
import { IStateProps } from "pages/completed/partial/endorsement";
import { Actions, Resources, userCan } from "tools/privilegeChecker";
import { paymentMethods } from "./paymentMethods";
import { paymentStatus } from "./paymentStatus";

const canSubmit = (itemStatus: number | undefined) => {
  return (
    itemActions.canSubmit.includes(itemStatus ? itemStatus : -1) &&
    userCan(Actions.SUBMIT, Resources.ITEM)
  );
};

const canDeposit = (
  itemStatus: number | undefined,
  itemPaymentMethodId: number | undefined,
  deluxeProcessableItem: boolean | undefined
) => {
  if (!itemStatus && itemStatus !== 0) {
    return false;
  }
  // If item is wire, it can not be deposited.
  if (itemPaymentMethodId == 4) {
    return false;
  }
  if (itemStatus == 0 && !deluxeProcessableItem) {
    return false;
  }
  return (
    itemActions.canDeposit.includes(itemStatus) &&
    userCan(Actions.DEPOSIT, Resources.ITEM)
  );
};

const canHold = (
  itemStatus: number | undefined,
  itemPaymentMethodId: number | undefined
) => {
  if (!itemStatus && itemStatus !== 0) {
    return false;
  }
  // If item is wire, it can not be hold.
  if (itemPaymentMethodId == 4) {
    return false;
  }
  return (
    itemActions.canHold.includes(itemStatus) &&
    userCan(Actions.HOLD, Resources.ITEM)
  );
};

const canReject = (itemStatus: number | undefined) => {
  if (!itemStatus && itemStatus !== 0) {
    return false;
  }
  return (
    itemActions.canReject.includes(itemStatus) &&
    userCan(Actions.REJECT, Resources.ITEM)
  );
};

const canChangeAmount = (itemStatus: number | undefined) => {
  if (!itemStatus && itemStatus !== 0) {
    return false;
  }
  return (
    itemActions.canChangeAmount.includes(itemStatus) &&
    userCan(Actions.CHANGE_AMOUNT, Resources.ITEM)
  );
};

const canOverrideItemStatus = (itemPaymentMethod: number | undefined) => {
  if (
    (!itemPaymentMethod && itemPaymentMethod !== 0) ||
    itemPaymentMethod === 1
  ) {
    return false;
  }
  return userCan(Actions.OVERRIDE_STATUS, Resources.ITEM);
};

const canReleaseHeld = (itemStatus: number | undefined) => {
  if (!itemStatus && itemStatus !== 0) {
    return false;
  }
  return (
    itemActions.canReleaseHeld.includes(itemStatus) &&
    userCan(Actions.RELEASE_HELD, Resources.ITEM)
  );
};

const canReturn = (
  itemStatus: number | undefined,
  itemPaymentMethod: number | undefined
) => {
  if (!itemStatus && itemStatus !== 0) {
    return false;
  }
  //ACH Transfers cannot be returned
  if (
    (!itemPaymentMethod && itemPaymentMethod !== 0) ||
    itemPaymentMethod === 1
  ) {
    return false;
  }
  return (
    itemActions.canReturn.includes(itemStatus) &&
    userCan(Actions.RETURN_ITEM, Resources.ITEM)
  );
};

const canChangeFileNumber = (itemStatus: number | undefined) => {
  if (!itemStatus && itemStatus !== 0) {
    return false;
  }
  return userCan(Actions.CHANGE_FILE_NUMBER, Resources.ITEM);
};

const canSendReminder = (itemStatus: number | undefined) => {
  if (itemStatus !== 21) {
    return false;
  }
  return userCan(Actions.SEND_REMINDER, Resources.PAYMENT_REQUEST);
};

const canValidate = (
  itemMethod: number | undefined,
  itemStatus: number | undefined,
  passportProcessableItem: boolean | undefined
) => {
  return (
    itemStatus == paymentStatus.NEW &&
    itemMethod == paymentMethods.CHECK_CAPTURE &&
    passportProcessableItem &&
    userCan(Actions.DEPOSIT, Resources.ITEM)
  );
};

export type DialogueType =
  | "approve"
  | "hold"
  | "reject"
  | "override"
  | "changeAmount"
  | "submit"
  | "multipleSubmit"
  | "releaseHold"
  | "changeFileNumber"
  | "return"
  | "sendReminder"
  | "validate"
  | "deleteRecurring"
  | "pause"
  | "unpause"
  | "clearSubmitAttempt"
  | "markAsCompleted";

export type dialogFields = {
  type: DialogueType;
  title: string;
  subtitle: string;
  iconPath?: string;
  dropdown?: IDropdownFieldProps;
  dropdownPaymentMethods?: boolean;
  endorsement?: IStateProps;
  endorsementStatus?: string;
  textField?: IInputFieldProps;
  extraMessage?: string;
  primaryBtn: IButtonProps;
  routeTypeId?: string;
  endorsementRequest?: EndorsementPositionRequest | undefined;
};

const itemActions = {
  canDeposit: [0, 1, 2, 19, 23, 29, 33],
  canSubmit: [4],
  canHold: [0, 1, 19],
  canReject: [0, 1, 2, 19, 21],
  canChangeAmount: [0, 1, 2],
  canReleaseHeld: [10],
  canReturn: [4, 10],
};

export {
  canSubmit,
  canDeposit,
  canHold,
  canReject,
  canChangeAmount,
  canOverrideItemStatus,
  canReleaseHeld,
  canReturn,
  canChangeFileNumber,
  canSendReminder,
  canValidate,
};
